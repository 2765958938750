import { CommandBar, FontIcon, Image, ICommandBarItemProps, Label, mergeStyles, Stack, Icon, List, Link, Text, ShimmeredDetailsList, IColumn, SelectionMode, Toggle, ProgressIndicator, ActionButton, IButtonStyles } from "@fluentui/react";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { ContractSemaphore, DigitalContractSignLink, Partner, PartnerContractResult, PartnerDigitalContract, SharepointFile } from "../../../Model/PartnerModels";
import facebookLogo from '../../../Assets/Images/facebook.png';
import instagramLogo from '../../../Assets/Images/instagram.png';
import twitterLogo from '../../../Assets/Images/twitter.png';
import linkedinLogo from '../../../Assets/Images/linkedin.png';
import youtubeLogo from '../../../Assets/Images/youtube.png';
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { t } from "i18next";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { EditIcon } from "@fluentui/react-icons-mdl2";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";

//#region Dashboard Container

export const DashboardContainer : React.FC<{}> = (props) => {

    const dashboardContainerClassName = mergeStyles([{
        width: "98%",
        margin: '0 auto',
        marginTop: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        paddingLeft: '1em',
        paddingBottom: '1em',
        paddingRight: '1em',
        paddingTop: '1em',
        boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
        borderLeftWidth: '0px',
        borderBottomWidth: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '2px',
        borderleftStyle: 'solid',
        borderbottomStyle: 'solid',
        borderRightStyle: 'solid',
        borderTopStyle: 'solid',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: 'transparent'
    }]);

    return(
        <Stack className={dashboardContainerClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region ActionBar

export interface IActionbarProps {
    items: ICommandBarItemProps[];
}

export const ActionBar : React.FC<IActionbarProps> = (props: IActionbarProps) => {

    const commandBarStyle = {
        root: {
            marginBottom: 10,
            padding: 0,
            borderBottom: '1px solid rgb(224, 224, 224)',
        }
    };

    const dashboardItemActionStyle = {
        root: {
            fontSize: 16,
            color: 'rgb(0, 132, 137) !important',
            backgroundColor: 'rgb(255,255,255) !important'
        },
        rootHovered: {
            textDecoration:'underline',
        },
        icon:{
            color: 'rgb(0, 132, 137) !important'
        }
    };

    let itemList = props.items;
    for (let i = 0; i < itemList.length; i++)
        itemList[i].buttonStyles = dashboardItemActionStyle;

    return(
        <CommandBar
            items={itemList}
            overflowItems={[]}
            styles={commandBarStyle}
        />
    )
}

//#endregion

//#region CarouselItem

export interface ICarouselItemProps {
    title: string;
    description: string;
    iconName?: string;
    onClick?: () => void;
}

export const CarouselItem : React.FC<ICarouselItemProps> = (props: ICarouselItemProps) => {

    return(
        <Stack key={"message2"} style={{
            width: 'auto',
            display: 'flex',
            flexWrap:'wrap-reverse',
            flexDirection:'row',
            justifyContent: 'space-around',
            height: 'auto',
            paddingLeft: 60,
            paddingBottom:40,
            backgroundColor: 'rgb(217, 240, 242) !important',
            borderRadius:'10px',
            margin:'0 auto'
        }}>
            <Stack.Item>
                <Stack.Item style={{
                    fontSize: 32,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    {props.title}
                </Stack.Item>
                <Stack.Item style={{
                    fontSize: 18,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    <Stack verticalFill style={{
                        textAlign: 'center'
                    }}
                    onClick={props.onClick}>
                        {props.description}
                    </Stack>
                </Stack.Item>
            </Stack.Item>
            <Stack.Item style={{paddingTop:'35px',margin:'0 auto'}}>
                <FontIcon aria-label="icon" iconName={props.iconName} style={{fontSize:100,opacity:'30%'}} />
            </Stack.Item>
        </Stack>
    )
}

//#endregion

//#region SharepointDocumentList

const formatBytes = (bytes?: number, decimals = 2): string => {
    if (bytes) {        
        if (!+bytes) return '0 MB'
        
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    else
        return "0 MB";
}

export interface ISharepointDocumentListProps {
    partnerId: number;
    items: SharepointFile[];
}

export const SharepointDocumentList : React.FC<ISharepointDocumentListProps> = (props: ISharepointDocumentListProps) => {

    const handleOnClick = (item?: SharepointFile) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/csp/sharepoint/file/"+props.partnerId+"/download";
        var promise = new Promise((resolve,reject)=>{
            instance.post(endpoint, item!, {responseType: 'blob'})
            .then((resp)=>{
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item!.fileName);
                document.body.appendChild(link);
                link.click();
                resolve(true);
            })
            .catch((resp)=>{
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })

        ToastService.showPromiseMessage(promise, t('partnerCenter:partnerDashboard:components:downloadStart')!, t('partnerCenter:partnerDashboard:components:downloadSuccess')!, t('partnerCenter:partnerDashboard:components:downloadError')!);
    }

    const onRenderCell = (item?: SharepointFile, index?: any, isScrolling?: boolean): JSX.Element => {
        let iconName: string = "FileTemplate";
        if (item?.fileExtension === "pptx")
            iconName = "PowerPointLogo";

        return (
          <Stack key={item?.fileName + "Entry"}  data-is-focusable={true} horizontal styles={{
              root: {
                  width: '100%',
                  selectors: {
                    "&:hover": {
                        backgroundColor: '#f2f2f2',
                        cursor: 'pointer'
                    }
                  }
              }
          }} onClick={()=>{handleOnClick(item);}}>
            <Stack.Item style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
                color: 'rgb(0, 128, 137)'
            }}>
                <Icon
                iconName={iconName}
                style={{
                    fontSize: 25
                }}
                />
            </Stack.Item>
            <Stack.Item style={{
                padding: 10
            }}>
                <div style={{
                    fontSize: 15,
                    fontWeight: 500
                }}>{item?.fileName} ({formatBytes(item?.fileSize, 2)})</div>
                <div style={{
                    fontSize: 10,
                    fontStyle: 'italic'
                }}>{t('partnerCenter:partnerDashboard:components:clickToDownload')!}</div>
            </Stack.Item>
          </Stack>
        );
      };
    
    if (props.items.length === 0)
      return(
        <Label>{t('partnerCenter:partnerDashboard:components:noDocs')!}</Label>
      );
    else
        return (
            <List items={props.items} onRenderCell={onRenderCell} />
        );
}

//#endregion

//#region Partner Social Slide

export const PartnerSocialSlide: React.FC = () => {
    const partnerInfoContext = useContext(PartnerInformationContext);
    
    const socialLogoImageClassName = mergeStyles([{
        cursor: 'pointer'
    }]);
    
    const buildSocialItem = (social: string): JSX.Element => {
        const legalEntityCode = partnerInfoContext.partner.legalEntityCode;
        switch (social)
        {
            case "facebook":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={facebookLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.facebook.com/so.smart.business")} />
                    </Stack.Item>
            case "instagram":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={instagramLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.instagram.com/so_smart_biz/")} />
                    </Stack.Item>
            case "youtube":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={youtubeLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.youtube.com/channel/UCb5qwkbMINv02sPk4YGWQ-g")} />
                    </Stack.Item>
            case "twitter":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={twitterLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://twitter.com/so_smart_biz")} />
                    </Stack.Item>
            case "linkedin":
                if (legalEntityCode === 'ESS')
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-us/")} />
                    </Stack.Item>
                else
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-business/")} />
                    </Stack.Item>
            default:
                return <></>;
            }
        }
    return (
        <Stack key={"socialSlideContainer"} style={{
            width: '100%',
            display: 'flex',
            minHeight: '320px',
            padding: 40,
            backgroundColor: 'rgb(217, 240, 242)'
        }}>
            <Stack.Item style={{
                fontSize: 32,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialTitle')}
            </Stack.Item>
            <Stack.Item style={{
                fontSize: 18,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                <div>
                    {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialDescription')}<br />
                    <Stack key="socialLogoContainer" horizontal tokens={{
                        childrenGap: 5
                    }} style={{
                        paddingTop: 20
                    }}>
                        {buildSocialItem("facebook")}
                        {buildSocialItem("instagram")}
                        {buildSocialItem("youtube")}
                        {buildSocialItem("twitter")}
                        {buildSocialItem("linkedin")}
                    </Stack>
                </div>
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Partner Contract Status

export interface IPartnerContractStatusProps {
    partner: Partner;
    fillCompanyData: () => void;
    handleIsContractSigned: (isSigned: boolean) => void;
}

export const PartnerContractStatus: React.FC<IPartnerContractStatusProps> = (props: IPartnerContractStatusProps) => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const partnerService = new PartnerService();
    const [contractData, setContractData] = useState<PartnerContractResult>(new PartnerContractResult());

    const getContractData = (fromUser?: boolean) => {
        setDataStatus(SystemDataLoadingStatus.Loading);

        if (!checkPartnerCompanyData()) {
            if (fromUser) {
                ToastService.showMessage(MessageType.Warning, t('partnerCenter:partnerDashboard:partnerContracts:alertCompanyData')!);
                props.fillCompanyData();
            }
            setDataStatus(SystemDataLoadingStatus.Loaded);
            return;
        }

        partnerService.getPartnerContractStatus(props.partner.id)
        .then((resp) => {
            setContractData(resp);
            props.handleIsContractSigned(resp.partnerDigitalContract.signed);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
    }

    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {
            getContractData();
        }
    }, [])

    const buildLabelStyle = (): CSSProperties => {
        const circleSytle: CSSProperties = {
            padding: 2,
            borderRadius: 10,
            minWidth: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            color: 'white',
            fontWeight: 'bold'
        };

        switch (contractData.status) {
            case ContractSemaphore.White:
                circleSytle.display = 'none';
                return circleSytle;
            case ContractSemaphore.Green:
                circleSytle.backgroundColor = 'green';
                return circleSytle;
            case ContractSemaphore.Yellow:
                circleSytle.backgroundColor = 'gold';
                circleSytle.color = 'black';
                return circleSytle;
            default:
                circleSytle.backgroundColor = 'firebrick';
                return circleSytle;
        }
    }

    const buildLabelText = (): string => {
        switch (contractData.status) {
            case ContractSemaphore.White:
                return t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:white:status')!;
            case ContractSemaphore.Green:
                    return t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:green:status')!;
            case ContractSemaphore.Yellow:
                return t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:yellow:status')!;
            default:
                return t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:red:status')!;
        }
    }

    const buildContractDescription = (): React.ReactNode => {
        if (contractData.partnerDigitalContract && contractData.partnerDigitalContract.id > 0)
            return <Text>{contractData.partnerDigitalContract.digitalContractDescription}</Text>;

        if (contractData.status === ContractSemaphore.White)
            return <Text>{t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:white:status')!}</Text>;
        
        return <Text>{t('partnerCenter:partnerDashboard:partnerContracts:noContractFound')!}</Text>;
    }

    const buttonStyle: IButtonStyles = {
        root: {
            backgroundColor: '#008089',
            borderRadius: 10,
            color: 'white',
        },
        rootHovered: {
            color: 'white',
            fontWeight: 'bold',
        },
        icon: {
            color: 'white',
        },
        iconHovered: {
            color: 'white',
            fontWeight: 'bold',
        },
        rootPressed: {
            color: 'white',
        },
        iconPressed: {
            color: 'white',
        }
    }

    const buildAction = (): React.ReactNode => {
        switch (contractData.status) {
            case ContractSemaphore.White:
                return <></>;
            case ContractSemaphore.Green:
                return <ActionButton iconProps={{iconName: 'Download'}} styles={buttonStyle} onClick={() => {downloadSigned(contractData.partnerDigitalContract)}}>
                    {t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:green:action')!}
                </ActionButton>;
            case ContractSemaphore.Yellow:
                return <ActionButton iconProps={{iconName: 'Refresh', style: {color: 'white'}}} styles={buttonStyle} onClick={() => { getContractData(true) }}>
                    {t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:yellow:action')!}
                </ActionButton>;
            default:
                return <ActionButton iconProps={{iconName: 'Add', style: {color: 'white'}}} styles={buttonStyle} onClick={() => { getContractData(true) }}>
                    {t('partnerCenter:partnerDashboard:partnerContracts:contractSemaphore:red:action')!}
                </ActionButton>;
        }
    }

    const buildSignListColumns = (): IColumn[] => {
        return [
            {
                key: "order",
                name: "#",
                minWidth: 25,
                maxWidth: 25,
                isResizable: false,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    return <Text>{item?.stepOrderIndex}</Text>
                }
            },
            {
                key: "signLink",
                name: t('partnerCenter:partnerDashboard:partnerContracts:signColumns:signLink')!,
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    return <Link href={item?.signLink} target="_blank" styles={{root: { display: 'flex', alignItems: 'center', justifyContent: 'center', gridGap: '5px' }}}>
                        <Text>{t('partnerCenter:partnerDashboard:partnerContracts:signColumns:digitalSignature')!}</Text>
                        <EditIcon  />
                    </Link>
                }
            },
            {
                key: "name",
                name: t('partnerCenter:partnerDashboard:partnerContracts:signColumns:name')!,
                minWidth: 150,
                maxWidth: 5000,
                isResizable: true,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    return <Text>{item?.stepFirstName} {item?.stepLastName}</Text>
                }
            },
            {
                key: "email",
                name: t('partnerCenter:partnerDashboard:partnerContracts:signColumns:email')!,
                minWidth: 150,
                maxWidth: 5000,
                isResizable: true,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    return <Text>{item?.stepEmail}</Text>
                }
            },
            {
                key: "signed",
                name: t('partnerCenter:partnerDashboard:partnerContracts:signColumns:signed')!,
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    return <Toggle checked={item?.signed}></Toggle>
                }
            },
            {
                key: "signedAt",
                name: t('partnerCenter:partnerDashboard:partnerContracts:signColumns:signedAt')!,
                minWidth: 150,
                maxWidth: 5000,
                isResizable: true,
                onRender: (item: DigitalContractSignLink | undefined, index?, column?) => {
                    if (!item?.signedAt)
                        return <></>

                    let userLocale: string = navigator.language || navigator.languages[0];
                    const intlOptions: Intl.DateTimeFormatOptions = {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                    };

                    let date = new Date(item.signedAt);

                    if (date.getFullYear() < 2000)
                        return <></>

                    const milliseconds = Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds(),
                    );
                    const localDate = new Date(milliseconds);
                    return <Text>{Intl.DateTimeFormat(userLocale, intlOptions).format(localDate)}</Text>;
                }
            }
        ];
    };
    
    const downloadSigned = (partnerDigitalContract: PartnerDigitalContract) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/customerDigitalContract/namirial/envelope/download/id/"+partnerDigitalContract.envelopeId;
        var promise = new Promise((resolve,reject)=>{
            instance.get(endpoint, {responseType: 'blob'})
            .then((resp)=>{
                let extension: string = "";
                if (resp.data.type === "application/zip")
                    extension = ".zip";
                else if (resp.data.type === "application/pdf")
                    extension = ".pdf";

                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', partnerDigitalContract.digitalContractDescription+extension);
                document.body.appendChild(link);
                link.click();
                resolve(true);
            })
            .catch((resp)=>{
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })

        ToastService.showPromiseMessage(
            promise,
            t('partnerCenter:partnerDashboard:partnerContracts:download:inProgress')!,
            t('partnerCenter:partnerDashboard:partnerContracts:download:success')!,
            t('partnerCenter:partnerDashboard:partnerContracts:download:failed')!
        );
    }

    const checkPartnerCompanyData = (): boolean => {
        const current: Partner = props.partner;
        let statusValue = true;
        let customerData = {
            companyName: current.name,
            email: current.email,
            firstName: current.firstName,
            lastName: current.lastName,
            phoneNo: current.phoneNo,
            streetAddress: current.streetAddress,
            zipCode: current.zipCode,
            city: current.city,
            county: current.county,
            country: current.country,
            // vat: current.vat,
            // pecEmail: current.pecEmail,
            legalRepresentativeFirstName: current.legalRepresentativeFirstName,
            legalRepresentativeLastName: current.legalRepresentativeLastName,
        };
        
        Object.keys(customerData).forEach((value: string, index: number, array: string[]) => {
            const currentValue = Object.values(customerData)[index];
            
            if (currentValue === undefined || currentValue === null || currentValue === "") {
                statusValue = false;
                
                // if (current.legalEntityCode === 'ESS') {
                //     if (value === "sdi" || value === "vat" || value === "pecEmail") {
                //         statusValue = true;
                //     } else {
                //         return false;
                //     }
                // } else {
                //     return false;
                // }
            }
        });
        
        return statusValue;
    };

    return (
        (dataStatus === SystemDataLoadingStatus.Loaded) ?
            <>
                <Stack
                    horizontal
                    tokens={{childrenGap: 20}}
                    style={{
                        maxWidth: '95%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 10
                    }}
                >
                    <Stack horizontal tokens={{childrenGap: 20}} style={{alignItems: 'center'}}>
                        <Stack.Item>
                            <div style={buildLabelStyle()}>
                                {buildLabelText()}
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            {buildContractDescription()}
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        {buildAction()}
                    </Stack.Item>
                </Stack>
                <Stack
                    horizontal
                    tokens={{childrenGap:20}}
                >
                    {contractData.signLinks && contractData.signLinks.length > 0 &&
                        <ShimmeredDetailsList
                            selectionMode={SelectionMode.none}
                            items={contractData.signLinks}
                            columns={buildSignListColumns()}
                            enableShimmer={!(dataStatus === SystemDataLoadingStatus.Loaded)}
                        />
                    }
                </Stack>
            </>
        :
            <ProgressIndicator label={t('partnerCenter:partnerDashboard:partnerContracts:loading')!} styles={{root: {width: '98%'}}} />
    );
}

//#endregion
