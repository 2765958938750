import {Icon, IImageStyleProps, IImageStyles, IStyleFunctionOrObject, mergeStyles, Stack,Text,Image, IStackTokens, PrimaryButton, DefaultButton, DirectionalHint, Spinner, SpinnerSize, TooltipHost, ImageFit, Panel, SearchBox, ProgressIndicator, Label, PanelType, Modal, ResponsiveMode, IconButton, mergeStyleSets, IButtonStyles, IDragOptions, ContextualMenu, FontWeights } from "@fluentui/react";
import React, {CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { SystemCore } from "../../../Core/System/SystemCore";
import { ITourEntry, SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { AppGroup, AppEntry, AppIndicatorType, AppSubGroup, AppActionType, TrialData, ECommerceCustomer, ECommerceUser, CustomerUser, AppChart, AppChartType, AppIndicatorSeverity, ChecklistItem, BCNProcessTest } from "../../../Model/DemoModels";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import ReactHtmlParser from 'html-react-parser';
import bcLogo from '../../../Assets/Images/BusinessCentral_scalable.svg';
import m365Logo from '../../../Assets/Images/Microsoft_365_(2022).svg';
import powerBILogo from '../../../Assets/Images/PowerBI_scalable.svg';
import powerAutomateLogo from '../../../Assets/Images/PowerAutomate_scalable.svg';
import { t } from "i18next";
import { CommonGuideTour } from "../../Common/CommonGuideTour/CommonGuideTour";
import checkListData from './RoleCenterChecklist.json';
import appRoleCenterData from './RoleCenterContent.json';
import { useNavigate } from "react-router-dom";
import { CommonVideoModal } from "../../Common/CommonVideoModal/CommonVideoModal";
import { ContentManagement, IContentManagement } from "../../../ApplicationCode/Common/ContentManagement";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { TrialModal } from "../TrialModal/TrialModal";
import { DemoUIHelper, IDemoDashboardData } from "../DemoUIHelper";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { CustomerTrialStatus } from "../../../Model/CustomerModels";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend, ArcElement, PointElement, LineElement, Filler,} from 'chart.js';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import { Chart } from 'react-chartjs-2';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { CookieService } from "../../../Core/Cookie/CookieHelper";
import pipelineGraph from '../../../Assets/Images/Pipeline Vendita.png';
import { KnownledgeRenderer } from "../../Docs/Old/Docs";
import { PartnerBookinAvailabilityList } from "../../Partner/PartnerBookinAvailabilityList/PartnerBookinAvailabilityList";
import { ApplicationOutletContext, useOutlet } from "../../Common/ApplicationContainer/ApplicationContainer";
import { Wizard } from "../../Common/Wizard/Wizard";
import { BookingSource, LeadQualifiedAnswer } from "../../../Model/PartnerModels";
import { googleAnalyticsManagement } from "../../../ApplicationCode/Common/GoogleAnalyticsManagement";
import Layout, { EmbeddedLayout } from "../../Chatbot/layout/Layout";

//#region Component: Content Row

export interface IRoleCenterContentRowProps {
    id: string;
}

const RoleCenterContentRowClassName = mergeStyles([{
    marginTop: '15px !important',
    display: 'flex',
    flexDirection:"row",
    flexFlow: 'row wrap',
    maxWidth: 'calc(100%)'
}]);

export const RoleCenterContentRow: React.FC<IRoleCenterContentRowProps> = (props) => {
    return (
        <Stack horizontal wrap key={"roleCenterContentRow" + props.id} className={RoleCenterContentRowClassName}>
            <Stack key={"roleCenterVerticalWidgets"} horizontal wrap  horizontalAlign="space-between" tokens={{childrenGap: 50}}>
                {props.children}
            </Stack>
        </Stack>
    );
}

//#endregion

//#region Component: Role Center Content Column

const RoleCenterContentColumnClassName = mergeStyles([{
    width: 'auto',
    float: 'left',
    marginLeft: "0px !important"
}]);

export const RoleCenterContentColumn: React.FC<{}> = (props) => {
    return (
        <Stack.Item className={RoleCenterContentColumnClassName}>
            {props.children}
        </Stack.Item>
    );
}

//#endregion

//#region Component: Role Center Content Container

export interface IRoleCenterContentContainerProps {
    name: string;
    title: string;
    extendedTitle?: string;
    id: string;
    onClick: () => void;
}

const roleCenterContainerContentClassName = mergeStyles([{
    display: 'flex',
    fleWrap: 'wrap',
}]);

const titleContainerClassName = mergeStyles([{
    width: '100%',
    borderBottom: '1px solid black',
    color: 'black',
    fontSize: 20,
    fontWeight: '500',
    paddingBottom: 5,
    userSelect: 'none',
    selectors: {
        "&:hover": {
            color: '#434242',
            cursor: 'pointer'
        }
    }
}]);

export const RoleCenterContentContainer: React.FC<IRoleCenterContentContainerProps> = (props) => {

    return (
        <Stack key={props.name} className={roleCenterContainerContentClassName}>
            <Stack.Item className={titleContainerClassName}>
                <Stack horizontal key={"dashboardTitleContainerContent-" + props.name} tokens={{childrenGap: 10}} horizontalAlign="space-between" style={{
                    width: '100%'
                }}>
                    <Stack.Item 
                        style={{
                            fontSize: 22,
                            fontWeight: 500,
                            color: 'inherit'
                        }}
                        id={props.id}
                        onClick={()=>props.onClick()}
                    >
                        <TooltipHost
                            content={props.extendedTitle ? props.extendedTitle : props.title}
                            id={"tootlip1-"+props.name}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{root:{display:'inline-block !important'}}}
                        >
                            {props.title}
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 15,
                        fontWeight: 500,
                        color: 'inherit',
                        marginRight: 15
                    }}>
                        <TooltipHost
                            content={t('demoRoleCenter:findOutMore')!}
                            id={"tootlip-"+props.name}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{root:{display:'inline-block !important'}}}
                        >
                            <Icon
                                iconName={'UnknownSolid'} 
                                styles={{root:{ 
                                    fontSize:22,
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    color: "#505C6D"
                                }}}
                                onClick={()=>props.onClick()}
                            />
                        </TooltipHost>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item id={"dashboardContainerContent-" + props.name} style={{
                paddingTop: 5
            }}>
                <Stack horizontal wrap tokens={{childrenGap: 10}}>
                    {props.children}
                </Stack> 
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Component: Cue Group

export interface ICueGroupProps {
    title: string;
    extendedTitle?: string;
    name: string;
    id: string;
    onClick: () => void;
}

export const CueGroup: React.FC<ICueGroupProps> = (props) => {

    return (
        <Stack key={"dashboardVerticalWidgets"}
            
        tokens={{
                childrenGap: 20
        }}>
            <RoleCenterContentColumn>
                <RoleCenterContentContainer
                    title={props.title}
                    extendedTitle={props.extendedTitle} 
                    name={props.name} 
                    id={props.id}
                    onClick={()=>props.onClick()}
                >
                    {props.children}
                </RoleCenterContentContainer>
            </RoleCenterContentColumn>
        </Stack>
    );
}

//#endregion

//#region Component: Sub Cue Group

export interface ISubCueGroupProps {
    title: string;
    id: string;
    onClick: () => void;
}

export const SubCueGroup: React.FC<ISubCueGroupProps> = (props) => {

    return (
        <Stack 
            verticalFill 
            verticalAlign="center" 
            wrap 
            tokens={{childrenGap: 10}}
            styles={{
                root: {
                    marginRight: 30
                }
            }}
        >
            <Stack.Item 
                tokens={{margin: 10}}
            >
                <Text
                    onClick={props.onClick}
                    variant="large"
                    styles={{
                        root:{
                            color: ' #838382',
                        }
                    }}
                    id={props.id}
                >
                    {props.title}
                </Text>
            </Stack.Item>
            <Stack 
                horizontal 
                wrap
                tokens={{childrenGap: 10}}
            >
                {props.children}
            </Stack>
        </Stack> 
    );
}

//#endregion

//#region Component: Content Data Box

export interface IContentDataBoxProps {
    name: string;
    title: string;
    extendedTitle?: string;
    value?: any;
    isIconBox?: boolean;
    iconName?: string;
    link:string;
    severity?: AppIndicatorSeverity;
    id?: string;
    videoId?: string;
    actionType?: AppActionType;
    isIndicatorCue?: Boolean;
    onClick?: (reference?: any) => void;
}

export const ContentDataBox: React.FC<IContentDataBoxProps> = (props) => {
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    const [videoResource, setVideoResource] = useState<IContentManagement>();
    const navigate = useNavigate();

    //#EDCF93 Ambiguous
    //#D9F0F9 Subordinate
    //#C22018 Unfavorable
    //#7AED6A Favorable
    
    let dataBoxKpiContentContainerClassName = mergeStyles([{
        position: 'relative'
    }]);
    
    const handleOnClick = ()=>{
        switch(props.actionType){
            case AppActionType.VideoModal:
                generateVideoContent();
                break;
            case AppActionType.InternalRedirect:
                navigate(props.link);
                break;
            case AppActionType.ExternalRedirect:
                window.open(props.link, '_blank');
                break;
            case AppActionType.Custom:
                props.onClick!();
                break;
        }
    }

    const generateVideoContent = ()=> {
        ContentManagement.generateResource(btoa(props.videoId!),props.title).then((resource)=>{
            CommonFunctions.logEvent("Apertura video dialog: " + props.title,"");
            setVideoResource(resource);
            setShowVideoModal(true);
        });
    }
    
    const getTitle = (isIndicator: Boolean | undefined)=>{
        if(isIndicator !== undefined && isIndicator)        
            if(props.title.length > 36)
                return props.title.substring(0, 33).trim() + "...";
            else
                return props.title;
        
        if(props.title.length > 22)
            return props.title.substring(0, 19).trim() + "...";
        else
            return props.title;
    }

    const getStyleFromSeverity = (secondaryStyle: string,hover: boolean, part: string) : string=> {
        switch(part){
            case "cue":
                switch(props.severity){
                    case AppIndicatorSeverity.Unfavorable:
                        return hover ? "#A32028" : "#C22018";
                    case AppIndicatorSeverity.Subordinate:
                        return hover ? "#D9F0F9" : "#D9F0F2";
                    default:
                        return secondaryStyle
                }
            case "line":
                switch(props.severity){
                    case AppIndicatorSeverity.Favorable:
                        return "5px solid #7AED6A";
                    case AppIndicatorSeverity.Ambiguous:
                        return "5px solid #EDCF93";
                    case AppIndicatorSeverity.Subordinate:
                        return "1px solid #505C6D";
                    case AppIndicatorSeverity.Unfavorable:
                        return "5px solid white";
                    default:
                        return secondaryStyle;
                }
            case "text":
                switch(props.severity){
                    case AppIndicatorSeverity.Subordinate:
                        return "#505C6D";
                    default:
                        return secondaryStyle;
                }
            default:
                return secondaryStyle;
        }
    }

    const getFontSize = (text: string, isIndicatorText: Boolean | undefined): number => {

        if(isIndicatorText !== undefined && isIndicatorText){
            if(text.length > 3)
                return 45;
            else
                return 50;
        }
        else{
            if(text.length > 3 && text.length < 6)
                return 34;
            else if(text.length < 7)
                return 45;
            else
                return 28;
        }
    }

    const generateIndicator = (): JSX.Element => {
        const normalCueBox : JSX.Element =
            <Stack 
                key={"dataBoxContainer-" + props.name} 
                id={props.id}
                styles={{
                    root:{
                        width: 128,
                        height: 144,
                        marginTop: 10,
                        paddingTop: 7,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: 'white',
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundColor: getStyleFromSeverity("#008089",false,"cue"),
                        selectors: {
                            "&:hover": {
                                backgroundColor: getStyleFromSeverity("#015d63",true,"cue"),
                            } 
                        }
                    }
                }}
                onClick={()=>handleOnClick()}
            >
                <Stack.Item className={dataBoxKpiContentContainerClassName}>
                    <Stack key="dataBoxDataContainer" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: 13,
                                    width: '100%',
                                    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif',
                                    height: 30,
                                    padding: 5 ,
                                    color: getStyleFromSeverity("white",false,"text")
                                }
                            }}
                        >
                            <TooltipHost
                                content={props.extendedTitle ? props.extendedTitle : props.title}
                                id={"tootlip-"+props.name}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                {getTitle(props.isIndicatorCue)} 
                            </TooltipHost>
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: getFontSize(props.value,props.isIndicatorCue),
                                    padding: 6,
                                    paddingTop: getFontSize(props.value,props.isIndicatorCue) <= 30 ? 15: 5,
                                    fontWeight: 300,
                                    height: 70,
                                    textAlign: 'left',
                                    width: '100%',
                                    color: getStyleFromSeverity("white",false,"text")
                                }
                            }} 
                        >
                            {
                                props.isIconBox ?
                                    <Icon 
                                        iconName={props.iconName}
                                        styles={{
                                            root:{
                                                padding: 10
                                            }
                                        }}
                                    />
                                :
                                    props.value
                            }
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: 13,
                                    borderTop: getStyleFromSeverity("1px solid white",false,"line"),
                                    width: 110,
                                    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif'
                                }
                            }}
                        >
                            <Icon iconName="ChevronRight" 
                                styles={{
                                    root: {
                                        paddingTop: 7,
                                        paddingLeft: 0,
                                        color: getStyleFromSeverity("white",false,"text")
                                    }
                            }} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack> 

        const indicatorCueBox : JSX.Element = 
            <Stack 
                key={"dataBoxIndicatorContainer-" + props.name} 
                onClick={()=>handleOnClick()}
                id={props.id}
                styles={{
                    root:{
                        width: 244,
                        height: 147,
                        marginTop: 3,
                        marginBottom: 3,
                        marginRight: 6,
                        paddingRight: 6,
                        color: 'black',
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundColor: "trasparent"
                    }
                }}
            >
                <Stack.Item className={dataBoxKpiContentContainerClassName}>
                    <Stack key="dataBoxDataContainer" 
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'start'
                        }}

                        verticalFill
                        verticalAlign="center"
                    >
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: 13,
                                    width: '70%',
                                    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif',
                                    height: 30,
                                    color: "black"
                                }
                            }}
                        >
                            <TooltipHost
                                content={props.extendedTitle ? props.extendedTitle : props.title}
                                id={"tootlip-"+props.name}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important',fontSize:14}}}
                            >
                                {getTitle(props.isIndicatorCue)} 
                            </TooltipHost>
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: getFontSize(props.value,props.isIndicatorCue),
                                    fontWeight: 300,
                                    height: 70,
                                    textAlign: 'left',
                                    width: '100%',
                                    color: "black"
                                }
                            }} 
                        >
                            {props.value}
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: 13,
                                    borderTop: "1px solid #838382",
                                    width: 110,
                                    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif'
                                }
                            }}
                        >
                            <></>
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root:{
                                    fontSize: 13,
                                    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif'
                                }
                            }}
                        >
                            <Icon iconName="ChevronRight" 
                                styles={{
                                    root: {
                                        paddingTop: 7,
                                        paddingLeft: 0,
                                        color: "#008089",
                                        fontSize: 16,
                                    }
                            }} />
                            <Text 
                                onClick={()=>handleOnClick()}
                                styles={{
                                    root:{
                                        color: "#008089",
                                        fontSize:16,
                                        ":hover":{
                                            color: "#015d63",
                                            textDecoration: "underline"
                                        }
                                    }
                                }}
                            >
                                {t('demoRoleCenter:cueBox:actionText')}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack> 
        
        
        return (
            <>
                {props.isIndicatorCue ? indicatorCueBox : normalCueBox}
            </>
        );
    }
    
    return (
        <>
            {generateIndicator()}
            <CommonVideoModal 
                showModal={showVideoModal} 
                key={"DemoVideoModal-" + props.name} 
                onDismiss={(isVideoPlayed:boolean)=>{
                    if(isVideoPlayed)
                        CommonFunctions.logEvent("Chiusura video dialog: "+props.title, "");
                    else
                        CommonFunctions.logEvent("Chiusura video dialog: "+props.title+", senza visione del contenuto", "");
                    
                        setShowVideoModal(false);
                }} 
                resource={videoResource!} 
            />
        </>
    );
}

//#endregion

//#region Component: Success Button

export interface ISuccessButtonProps {
    text: string;
    hidden?: boolean;
    onClick: () => void;
}

const successButtonClassName = mergeStyles([{
    backgroundColor: '#008089',
    borderColor: '#008089',
    selectors: {
        "&:hover": {
            backgroundColor: '#015d63',
            borderColor: '#015d63',
        }
    }
}]);

export const SuccessButton: React.FC<ISuccessButtonProps> = (props) => {
    return (
        <PrimaryButton 
            onClick={props.onClick} 
            text={props.text}
            className={successButtonClassName}
            hidden={props.hidden}
        />
    );
}

//#endregion

//#region Component: Cancel Button
export interface ICancelButtonProps {
    text: string;
    onClick: () => void;
}

export const CancelButton: React.FC<ICancelButtonProps> = (props) => {
    return (
        <DefaultButton 
            onClick={props.onClick} 
            text={props.text} 
        />
    );
}

//#endregion

//#region Component: Banner

export interface IBannerProps {
    text: string;
    hasSubtext?: boolean;
    subText?: string;
}

export const Banner: React.FC<IBannerProps> = (props) => {

    const mainContainer = mergeStyles([{
        width: '100%',
        backgroundColor: '#f2f2f2'
    }]);

    const leftContainer = mergeStyles([{
        width: '40%',
        maxHeight: '45vh'
    }]);

    const leftContentContainer = mergeStyles([{
        paddingTop: 28,
        width: '100%',
        height: '100%'      
    }]);

    const leftContentContainerTokens: IStackTokens = {
        childrenGap: 30
    }

    const leftMainText = mergeStyles([{
        fontSize: '1.7vw',
        paddingLeft: 45,
        fontWeight: 500,
        fontFamily: 'Nunito',
        color: 'black',
        width: '85%'  
    }]);

    const leftSubText = mergeStyles([{
        fontSize: '1vw',
        paddingLeft: 45,
        fontWeight: 300,
        fontFamily: 'Nunito',
        color: '#424241',
        width: '85%'  
    }]);

    const leftImage: IStyleFunctionOrObject<IImageStyleProps, IImageStyles> = {
        image: {
            width: '3vw',
            height: 'auto'
        }
    };

    const rightContainer = mergeStyles([{
        width: '60%',
        padding: 25
    }]);

    const rightContentContainer = mergeStyles([{
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        maxHeight: '40vh'
    }]);
    
    return (
        <Stack horizontal className={mainContainer} key="bannerMainContainer" id="bannerContent">
            <Stack.Item className={leftContainer}>
                <Stack className={leftContentContainer} tokens={leftContentContainerTokens} key={"leftContentContainer"}>
                    <Stack.Item className={leftMainText}>
                        {props.text}
                    </Stack.Item>
                    {
                        props.hasSubtext ?
                        <Stack.Item className={leftSubText} id="gtBannerContent">
                            {props.subText}
                        </Stack.Item>
                        :
                        <></>
                    }
                    <Stack verticalAlign="center" verticalFill styles={{root:{position:'relative'}}}>
                        <Stack horizontal horizontalAlign="space-around" styles={{root:{position:'absolute',bottom:'20px',left:'0px',width:'100%'}}}>
                            <TooltipHost
                                content={"Dynamics 365 Business Central"}
                                id={"tootlipBC"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={bcLogo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft 365"}
                                id={"tootlipM365"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={m365Logo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft Power BI"}
                                id={"tootlipPowerBI"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={powerBILogo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft Power Automate"}
                                id={"tootlipMPA"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={powerAutomateLogo} styles={leftImage} />
                            </TooltipHost>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item className={rightContainer}>
                <Stack horizontal className={rightContentContainer} key={"rightContentContainer"}>
                    {props.children}
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Component: Check List Item

export interface IChecklistProps {
    items: ChecklistItem[];
    onStart: (item: ChecklistItem) => void;
    onCancel: (item: ChecklistItem) => void;
}

export const Checklist: React.FC<IChecklistProps> = (props) => {
    const [currentChecklistItem, setCurrentChecklistItem] = useState<ChecklistItem>({} as ChecklistItem);
    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);

    const containerStyle = mergeStyles([{
        width: '100%',
        height: '100%'
    }]);

    const textContainerClassName = mergeStyles([{
        width: '100%',
        paddingTop: 20,
        marginLeft: 20,
    }]);

    const textClassName = mergeStyles([{
        fontFamily: 'Nunito',
        fontWeight: 500
    }]);

    const getCurrentChecklistItem = (id: string | null) => {
        let currentEntry: ChecklistItem | undefined = undefined;
        
        for(let i = 0; i < props.items.length; i++)
            if(!props.items[i].completed)
                props.items[i].active = false;

        if(id !== null)
            currentEntry = props.items.find(item => item.id === id);

        if(currentEntry !== undefined){
            currentEntry.active = true;
            setCurrentChecklistItem(currentEntry);
        }
        else{
            props.items[0].active = true;
            setCurrentChecklistItem(props.items[0]);
        }
    }
    
    let entries: JSX.Element[] = [];
    let cookie = CookieService.getCustomCookie('CHECKLIST');
    for(let j = 0; j < props.items.length; j++)
    { 
        let currentId = props.items[j].id;

        if(cookie?.includes(currentId)){
            props.items[j].completed = true;
            props.items[j].active = true;
        }

        entries.push(
            <CheckListItem
                id={props.items[j].id}
                key={props.items[j].id}
                title={props.items[j].shortTitle}
                active={props.items[j].active}
                completed={props.items[j].completed}
                onClick={getCurrentChecklistItem}
            />
        );

        if ((j + 1) !== props.items.length)
            entries.push(<CheckListSeparator
                            active={props.items[j + 1].active} />);
            
    }

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            setDataStatus(SystemDataLoadingStatus.Loading);
            getCurrentChecklistItem(null);
            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    },[dataStatus])

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return (<ChecklistLoader />)

    return (
        <Stack className={containerStyle}>
            <Stack className={textContainerClassName}>
                <Text variant="large" className={textClassName}>{t("demoRoleCenter:banner:rightContent:title")!}</Text>
            </Stack>
            <Stack key={"checklist"} className={containerStyle} horizontal id="inventoryApp">
                <CheckListContainer>
                    {entries}
                </CheckListContainer>
                <ChecklistContentContainer>
                    <ChecklistContentEntry item={currentChecklistItem} onStart={props.onStart} onCancel={props.onCancel}  />
                </ChecklistContentContainer>
            </Stack>
        </Stack>
    );
}

//#endregion

//#region Component: Check List Container

export const ChecklistContentContainer: React.FC<{}> = (props) => {
    const containerStyle = mergeStyles([{
        padding: 50,
        paddingTop: 40,
    }]);

    const tokens: IStackTokens = {
        childrenGap: 20
    }
    
    return (
        <Stack key={"checklistContentContainer"} className={containerStyle} tokens={tokens}>
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Component: Check List Content Entry

export interface IChecklistContentEntryProps {
    item: ChecklistItem;
    onStart: (item: ChecklistItem) => void;
    onCancel: (item: ChecklistItem) => void;
}

export const ChecklistContentEntry: React.FC<IChecklistContentEntryProps> = (props) => {
    const titleStyle = mergeStyles([{
        color: 'black',
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '1.1vw'
    }]);

    const descriptionStyle = mergeStyles([{
        color: '#424241',
        fontFamily: 'Nunito',
        fontSize: '0.9vw',
        fontWeight: 300,
        maxHeight: '40%',
        overflowY:'auto',
        height: 'calc(45%)'
    }]);

    const buttonContainer = mergeStyles([{
    }]);

    const tokens: IStackTokens = {
        childrenGap: 10
    }
    
    return (
        <>
            <Stack.Item className={titleStyle}>
                {props.item.title}
            </Stack.Item>
            <Stack.Item className={descriptionStyle}>
                {ReactHtmlParser(props.item.description)}
            </Stack.Item>
            <Stack.Item className={buttonContainer}>
                <Stack key={"buttonContentContainer"} horizontal tokens={tokens}>
                    <Stack.Item>
                        <SuccessButton hidden={!props.item.clickable} text={props.item.primaryButtonText} onClick={() => props.onStart(props.item)}/>
                    </Stack.Item>
                    <Stack.Item>
                        {props.item.mandatory ? <></> : <CancelButton text={props.item.defaultButtonText} onClick={() => props.onCancel(props.item)} />}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </>
    );
}

//#endregion

//#region Component: Check List Container

export const CheckListContainer : React.FC<{}> = (props) => {
   
    const parentStyle = mergeStyles([{
        maxHeight: '35vh',
        borderRight: '1px solid #AFAFAF',
        marginTop: 32,
        marginBottom: 32,
        paddingLeft: 40
    }]);

    const containerStyle = mergeStyles([{
        paddingRight: '2vw',
        maxHeight: '35vh',
        overflowY: 'auto',
        width: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        selectors: {
            "::-webkit-scrollbar": {
                width: 7
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: '#f1f1f1'
            },
            "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555"
            }
        }
    }]);

    return (
        <Stack.Item className={parentStyle}>
            <Stack key={"checkListContainer"}
                className={containerStyle}>
                {props.children}
            </Stack>
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Item

export interface ICheckListItemProps {
    id: string;
    key: string;
    title: string;
    active?: boolean;
    completed?: boolean;
    onClick: (itemId: string) => void;
}

export const CheckListItem : React.FC<ICheckListItemProps> = (props) => {    
    const circleStyle = mergeStyles([{
        backgroundColor: props.active ? (props.active? '#008089' : 'inherit') : 'inherit',
        border: props.active ? (props.completed? 'none' : '3px solid #008089') : '3px solid #AFAFAF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        borderRadius: 100,
        height: 20,
        minHeight: 20,
        width: 20,
        selectors: {
            "&:hover": {
                cursor: 'pointer'
            }
        }
    }]);

    const titleStyle = mergeStyles([{
        color: props.active ? '#015d63' : '#5E5F5F',
        fontFamily: 'Nunito',
        fontSize: '1.7vh',
        display: 'flex',
        fontWeight: props.active ? 500 : 300,
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: 15
    }]);

    const completeStepTiitleStyle = mergeStyles([{
        color: '#015d63',
        fontFamily: 'Nunito',
        fontSize: '1.7vh',
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: 15
    }]);
    
    return (
        <Stack.Item>
            <Stack id={props.id} key={"checkListItem" + props.key} horizontal onClick={()=>props.onClick(props.id)!}>
                <Stack.Item>
                    <div className={circleStyle}>
                        {props.completed ? <Icon iconName="CheckMark" /> : <></>}
                    </div>
                </Stack.Item>
                <Stack.Item className={props.completed ? completeStepTiitleStyle : titleStyle}>
                    {props.title}
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Separator

export interface ICheckListSeparatorProps {
    active?: boolean;
}

export const CheckListSeparator : React.FC<ICheckListSeparatorProps> = (props) => {
    const circleStyle = mergeStyles([{
        backgroundColor: props.active ? '#008089' : '#AFAFAF',
        height: 15,
        minHeight: 15,
        width: 4,
        marginTop: 2.5,
        marginBottom: 2.5
    }]);

    const containerStyle = mergeStyles([{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20
    }]);
    
    return (
        <Stack.Item className={containerStyle}>
            <div className={circleStyle} />
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Loader

export const ChecklistLoader: React.FC<{}> = (props) => {

    return (
        <div className="loader" style={
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#f2f2f2'
            }
        }>
            <div>
                <Spinner 
                    label={"Loading..."}
                    size={SpinnerSize.large} 
                    styles={{
                        label: {
                            fontSize: 13
                        },
                        circle: {
                            width: 70,
                            height: 70,
                            borderWidth: 2.5,
                            marginBottom: 10
                        }
                    }}
                />
            </div>
        </div>
    );
}

//#endregion

//#region Component: Role Center Help Panel

export interface IRoleCenterHelpPanelProps {
    isOpen: boolean;
    value:string;
    kbList?: BCNProcessTest[];
    dataStatus: SystemDataLoadingStatus;
    customStyle?: CSSProperties;
    onDismiss: () => void;
    onEscape?: (ev?: any) => void;
    onClear?: (ev?: any) => void;
    onBlur?: (ev?: any) => void;
    onChange?: (ev?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => void;
    onSearch?: (newValue?: string | undefined) => void;
}

export const RoleCenterHelpPanel : React.FC<IRoleCenterHelpPanelProps> = (props) => {
    const [videoContent,setVideoContent] = useState<IContentManagement>({} as IContentManagement);
    const [showVideoModal,setShowVideoModal] = useState<boolean>(false);
    const [showEmbeddedHelp,setShowEmbeddedHelp] = useState<boolean>(false);
    const [embeddedContentId,setEmbeddedContentId] = useState<string>("");
    const [jsxElements, setjsxElements] = useState<JSX.Element[]>([] as JSX.Element[]);

    const sessionInfoContext = useContext(SessionStateContext);
    
    const roleCenterHelpPanelClassName = mergeStyles([{
        top: "52px !important",
        height: 'calc(100% - 51px) !important',
        zIndex: "10 !important",
    }]);
    
    const helpPanelContainerClassName = mergeStyles([{
        overflow: 'hidden',
        height: '100%'
    }]);
    
    const getResulElements = () => {
        let elements: JSX.Element[] = [];

        if (props.dataStatus === SystemDataLoadingStatus.ToLoad){
            elements.push(<></>);
        }
        else if (props.dataStatus === SystemDataLoadingStatus.Loading){
            elements.push(<ProgressIndicator label={t('common:GuidePanel:waitingMessage')!} description={t('common:GuidePanel:waitingDescription')!} />);
        }
        else if (props.kbList === undefined || props.kbList.length === 0){
            elements.push(<Label>{t('common:GuidePanel:emptyResults')!}</Label>);
        }
        else{
            for (let i = 0; i < props.kbList.length; i++)
            {
                elements.push(
                    <RoleCenterHelpCard
                        key={props.kbList![i].processModule+props.kbList![i].processCode+props.kbList![i].lineNo}
                        title={props.kbList[i].descriptionCaption}
                        onClick={()=>{
                            DemoUIHelper.openKB(HttpHelper.buildAxiosInstance(), props.value, props.kbList![i].crmReferenceId)
                            .catch((err)=>{
                                console.log(err.message);
                            })
                            .finally(()=>{
                                if(sessionInfoContext.currentApp.homepageUrl.includes('demo'))
                                    CommonFunctions.logEvent("Ricerca guida: "+props.kbList![i].description,"");

                                CommonFunctions.disablePageScroll();
                                setEmbeddedContentId("");
                                setEmbeddedContentId(props.kbList![i].processModule+'/'+props.kbList![i].processCode+'/'+props.kbList![i].lineNo)
                                setShowEmbeddedHelp(true);
                            })
                    }} />
                );
            }
        }
        
        setjsxElements(elements);
    }
    
    
    useEffect(() => {
        getResulElements();
    }, [props.kbList]);

    return (
        <>
            <Panel
                styles={{
                    content:{
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        height: 'calc(100% - 50px)'
                    },
                    contentInner: {
                        height: '100%'
                    },
                    scrollableContent: {
                        height: '100%'
                    },
                    main:{
                        boxShadow: 'none !important',
                        backgroundColor:'#FAF9F8',
                        borderLeft: "1px solid #bababa"
                    },
                    commands:{
                        boxShadow: 'none !important',
                        backgroundColor:'#FAF9F8'
                    },
                    root:{
                        zIndex: 10
                    }
                }}
                layerProps={{ styles: { root: { zIndex: props.customStyle?.zIndex ? props.customStyle.zIndex : 10000 }}}}
                className={roleCenterHelpPanelClassName}
                isOpen={props.isOpen}
                isBlocking={false}
                onDismiss={props.onDismiss}
                headerText="Chatbot"
                customWidth={'20%'}
                onRenderHeader={() => <></>}
                type={PanelType.custom}
            >
                <Stack className={helpPanelContainerClassName}>
                    <EmbeddedLayout></EmbeddedLayout>
                </Stack>
            </Panel>
            <CommonVideoModal 
                showModal={showVideoModal} 
                key={"VideoModal"} 
                onDismiss={(isVideoPlayed:boolean)=>{setShowVideoModal(false);}} 
                resource={videoContent!} 
            />
            {
                embeddedContentId !== "" ?
                
                    <RoleCenterEmbeddedHelp
                        contentId={embeddedContentId}
                        onDismiss={()=>{
                            let guideTourElement = document.getElementById("CommonGuideTourDemo");

                            if(guideTourElement !== null)
                                CommonFunctions.disablePageScroll();
                            else
                                CommonFunctions.enablePageScroll();

                            setShowEmbeddedHelp(false);
                        }}
                        showModal={showEmbeddedHelp}
                        key={"RoleCenterEmbeddedHelp"}
                        hideTicketOption={true}
                    />
                :
                    <></>
            }
        </>
    )
}

//#endregion

//#region Component: Role Center Help Card

export interface IRoleCenterHelpCardProps {
    title: string;
    onClick: () => void;
}

export const RoleCenterHelpCard : React.FC<IRoleCenterHelpCardProps> = (props) => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    const helpCardContainerClassName = mergeStyles([{
        width: '100%',
        marginBottom: '15px',
    }]);

    const buttonClassName = mergeStyles([{
        width:"100%",
        height:"auto",
        fontFamily: 'Nunito',
        textAlign: 'left',
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: 300,
        color: '#008089',
        userSelect: 'none',
        ":hover":{
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#008089',
        },
        ":active":{
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#015d63',
        }
    }]);

    const textContainerClassName = mergeStyles([{
        marginBottom: '15px',
        cursor: 'pointer'
    }]);

    return (
        <Stack className={helpCardContainerClassName}>
            <Stack verticalFill verticalAlign="space-between">
                <Stack.Item
                    className={textContainerClassName}
                    onClick={()=>setIsContentVisible(!isContentVisible)}
                >
                    <PrimaryButton  
                        className={buttonClassName}
                        iconProps={{iconName:'Documentation'}}
                        onClick={()=>{props.onClick()}}
                    >
                        {props.title}
                    </PrimaryButton>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}

//#endregion

//#region Component: RoleCenter Embedded Help

export interface IRoleCenterEmbeddedHelpProps {
    contentId:string;
    showModal:boolean;
    customStyle?: CSSProperties;
    hideTicketOption?: boolean;
    onDismiss: () => void;
}

export const RoleCenterEmbeddedHelp : React.FC<IRoleCenterEmbeddedHelpProps> = (props) => {

    const ContentStyles = mergeStyleSets({
        Container: {
            height:'calc(100vh - 80px)',
            width: "78%",
            marginTop: '10px',
            paddingLeft: '1em',
            paddingBottom: '1em',
            paddingRight: '1em',
            paddingTop: '1em',
            transition: 'width 0.01s linear'
        },
        Body: {
            padding: '0 10px 10px 10px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            }
        }
    });

    const buttonStyles = mergeStyles([{
        backgroundColor: 'transparent',
        border: 'none',
        fontFamily: 'Nunito',
        fontWeight: 300,
        color: '#377CD8',
        userSelect: 'none',
        ":hover":{
            color:"#377CD8",
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            border: 'none',
        },
        ":active":{
            color:"#377CF9",
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            border: 'none',
        }
    }]);

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: "black",
            marginLeft: 'auto',
            marginRight: '2px',
        },
        rootHovered: {
            color: "gray",
        }
    };

    return(
        <Modal
            titleAriaId={"ModalVideoContent"}
            isOpen={props.showModal}
            onDismiss={props.onDismiss}
            isBlocking={true}
            containerClassName={ContentStyles.Container}
            dragOptions={undefined}
            responsiveMode={ResponsiveMode.large}
            isModeless
            forceFocusInsideTrap={false}
            layerProps={{
                styles:{root:{zIndex: '8000000 !important'}}
            }}
            styles={{
                main:{ 
                    width: '79% !important',
                    maxWidth: 'calc(79%) !important',
                    maxHeight: 'calc(100vh - 80px)',
                    position: 'absolute',
                    top: 55,
                    left: 5,
                    overflowY: 'hidden'
                },
                scrollableContent:{
                    overflowY: 'hidden',
                },
            }}
        >   
            <Stack horizontal horizontalAlign="space-between" styles={{root:{marginBottom:10}}}>
                    <PrimaryButton
                        className={buttonStyles}
                        onClick={props.onDismiss}
                        iconProps={{iconName:"Back"}}
                    >
                        {window.location.pathname.includes('roleCenter') ? t('demoRoleCenter:embeddedHelp:demoButtonText') : t('demoRoleCenter:embeddedHelp:defaultButtonText')}
                    </PrimaryButton>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={props.onDismiss}
                    />
            </Stack>
            <KnownledgeRenderer contentId={props.contentId} hideOpenTicket={props.hideTicketOption} />
        </Modal>
    )
}

//#endregion

//#region Component: RoleCenter Booking modal

export interface IRoleCenterBookingModal {
    showModal:boolean;
    onDismiss: () => void;
}

export const RoleCenterBookingModal : React.FC<IRoleCenterBookingModal> = (props) => {
    const sessionInfoContext = useContext<ISessionStateContext>(SessionStateContext);

    const ContentStyles = mergeStyleSets({
        Container: {
            width: "78%",
            marginTop: '10px',
            paddingLeft: '1em',
            paddingBottom: '1em',
            paddingRight: '1em',
            paddingTop: '1em',
            transition: 'width 0.01s linear',
            zIndex: 10,
        },
        Body: {
            padding: '0 10px 10px 10px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            }
        }
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: "black",
            marginLeft: 'auto',
            marginRight: '2px',
        },
        rootHovered: {
            color: "gray",
        }
    };

    return(
        <Modal
            titleAriaId={"BookingModal"}
            isOpen={props.showModal}
            onDismiss={props.onDismiss}
            containerClassName={ContentStyles.Container}
            dragOptions={undefined}
            responsiveMode={ResponsiveMode.large}
            styles={{
                main:{ 
                    width: '79% !important',
                    maxWidth: 'calc(79%) !important',
                    maxHeight: 'calc(100vh - 80px)',
                    borderRadius: 10
                },
                scrollableContent:{
                    overflowY: 'hidden'
                },
            }}
        >
            <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <Text variant="xLarge">{t("demoRoleCenter:bookingModal:title")!}</Text>
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={props.onDismiss}
                    />
                </Stack.Item>
            </Stack>
            <PartnerBookinAvailabilityList
                mode="lead"
                leadId={sessionInfoContext.currentUser.leadId}
                leadName={""}
                key={"BookingListModal"}
                source={BookingSource.Demo}
                isModal
            />
        </Modal>
    )
}

//#endregion

//region Component: RoleCenter Wizard modal

export interface IRoleCenterWizardModalProps {
    showModal:boolean;
    onDismiss: () => void;
}

export const RoleCenterWizardModal : React.FC<IRoleCenterWizardModalProps> = (props) => {
    
    //#region Styles

    const dragOptions:IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds:true,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
    }
    
    const ContentStyles = mergeStyleSets({
        Container: {
            width:'calc(100vw - 288px)',
            zIndex:"20000 !important",
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflowY: 'hidden',
        },
        Header:{
            borderTop: `4px solid #008089`,
            color: "black",
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
        Body: {
            overflowY: 'hidden',
            backgroundColor: 'red',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            }
        }
    });

    const wizardContainerClassName = mergeStyles([{
        backgroundImage: 'url(\'./wizardBackground.png\')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }]);

    //#endregion

    return(
        <Modal
            titleAriaId={"ModalVideoContent"}
            isOpen={props.showModal}
            onDismiss={props.onDismiss}
            isBlocking={true}
            containerClassName={ContentStyles.Container}
            dragOptions={undefined}
            responsiveMode={ResponsiveMode.large}
            styles={{scrollableContent:{overflowY: 'hidden'}}}
        >
            <Wizard
                wizardCookieName="WZDemoRC"
                containerClassName={wizardContainerClassName}
                onCompleteWizard={props.onDismiss}
            />
        </Modal>
    );
}

//#endregion

export const DemoRoleCenter : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderDataStatus, setLoaderDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [roleCenterContent, setRoleCenterContent]= useState<JSX.Element[]>([<></>]);
    const [guideTourElement,setGuideTourElement] = useState<JSX.Element | undefined>(<></>);
    const [guideTourEntry,setGuideTourEntry] = useState<ITourEntry[]>([]);
    const [checkListItems,setChecklistItems] = useState<ChecklistItem[]>([]);
    const [trialData,setTrialData] = useState<TrialData>({} as TrialData);
    const [dashboardData,setDashboardData] = useState<IDemoDashboardData>({} as IDemoDashboardData);
    const [showTrialModal,setShowTrialModal] = useState<boolean>(false);
    const [saveInProgress,setSaveInProgress] = useState<boolean>(false);
    const [validationErrors,setValidationErrors] = useState<Object[]>([]);
    const [showBookingModal,setShowBookingModal] = useState<boolean>(false);
    const [forceUpdate,setForceUpdate] = useState<boolean>(false);
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    const [videoResource, setVideoResource] = useState<IContentManagement>();
    const [disableVideoLight, setDisableVideoLight] = useState<boolean>(false);
    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState<boolean>(false);
    const [demoWizardVisible, setDemoWizardVisible] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<number>(0);
    const analytics = new googleAnalyticsManagement();

    const canValidateErrors = useRef<boolean>(false);
    const sessionInfoContext = useContext<ISessionStateContext>(SessionStateContext);

    const outletContext:ApplicationOutletContext = useOutlet();
    
    let guideTourClose:boolean = false;
    let defaultCurrecySymbol:string = "";

    const fieldContainerClassName =mergeStyles([{
        width: "calc(100vw - 95%) !important",
        height: "calc(100vw - 70%) !important",
        maxWidth: "calc(100vw - 70%) !important",
        maxHeight: "calc(100vw - 70%) !important",
    }]);

    const chartDoughnutContainerClassName =mergeStyles([{
        width: "calc(100vw - 95%) !important",
        height: "calc(100vw - 90%) !important",
        maxWidth: "calc(100vw - 95%) !important",
        maxHeight: "calc(100vw - 90%) !important",
    }]);

    const imageClassName = mergeStyles([{
        width: "100% !important",
        height: "100% !important"
    }]);

    const RoleCenterContainerClassName = mergeStyles([{
        width: "88%",
        margin: '0 auto',
        marginTop: '10px',
        paddingLeft: '1em',
        paddingBottom: '1em',
        paddingRight: '1em',
        paddingTop: '1em',
        zIndex: 10,
    }]);
    
    const checkLeadTrialStatus = (cheklistId: string) => {
        CommonFunctions.logEvent("Apertura form per prova gratuita", "");

        DemoUIHelper.loadLead(HttpHelper.buildAxiosInstance(), trialData.currentLead.id).then((resp) => {
            if(resp!.isTrial > 0){
                let core: SystemCore = new SystemCore(); 
                window.location.replace((process.env.REACT_APP_CUSTOMER_PORTAL_ADDRESS+btoa(core.getUserToken())));

                setForceUpdate(!forceUpdate);
            }
            else
            {
                let currTrialData: TrialData = trialData;
                let currentResp = resp!;
                currentResp.lastname = "";
                currentResp.city = "";
                currentResp.county = "";
                currentResp.country = "";

                currTrialData.confirmPassword = trialData.confirmPassword;
                currTrialData.currentLead = currentResp!;
                currTrialData.customerUser = trialData.customerUser;

                CommonFunctions.logEvent("Conferma prova gratuita", "");

                setShowTrialModal(true);
                canValidateErrors.current = true;
            }

            setChecklistCookie(cheklistId);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("demoCenter:Err00001Text"));
        })
    }

    const createGuideTourElement = (elementIndex:number) => {
        let totalEntryCount:number = 0;
        let currentTotalGuideEntry : ITourEntry[] = [];
        let currentIndex:number = 0;

        currentTotalGuideEntry = guideTourEntry;
        totalEntryCount = guideTourEntry.length;

        currentIndex = elementIndex + 1;
        let currentEntry = currentTotalGuideEntry[elementIndex];

        setTeachingBubbleVisible(false);

        CommonFunctions.disablePageScroll();

        if(currentEntry !== undefined){
            CommonFunctions.logEvent("Inizio step " + currentIndex + " del tour guidato", "");

            let primaryButtonLabel:string =t('customerCenter:CommonGuideTourDialog:tourNextButtonText');
            if(currentIndex === totalEntryCount)
                primaryButtonLabel = t('customerCenter:CommonGuideTourDialog:tourFinishButtonText');
            
            let element = document.getElementById(currentEntry.targetId);
            if (currentEntry.canScroll && !isInViewport(element))
            {
                element?.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"});

                setGuideTourElement(
                    <CommonGuideTour
                        id="CommonGuideTourDemo"
                        customStyle={{backgroundColor: '#00B7C3'}} 
                        currentEntry={currentEntry} 
                        currentIndex={currentIndex}
                        totalSteps={totalEntryCount} 
                        primaryButtonLabel={primaryButtonLabel}
                        isFooterAction
                        actionKeyword={currentEntry.actionKeyword}
                        secondaryButtonLabel={t('customerCenter:CommonGuideTourDialog:tourDefaultButtonText')} 
                        onNext={()=>{createGuideTourElement(elementIndex+1)}}
                        onFooterAction={(keyWord:string)=>{
                            CommonFunctions.logEvent("Click maggiori informazioni tour guidato: "+currentEntry.title,"");

                            if(keyWord !== undefined && keyWord !== "")
                                outletContext.onOpenGuidePanel(keyWord);
                            else if(currentEntry.clickable)
                                outletContext.onOpenGuidePanel(currentEntry.title);
                        }}
                        onCancel={()=>{
                            CommonFunctions.logEvent("Chiusura del tour guidato", "");
                            setGuideTourElement(undefined);
                            guideTourClose = true;
                            CommonFunctions.enablePageScroll();
                        }} 
                        onDismiss={()=>{
                            CommonFunctions.logEvent("Chiusura del tour guidato", "");
                            setGuideTourElement(undefined);
                            guideTourClose = true;
                            CommonFunctions.enablePageScroll();
                        }}
                    />
                );

                window.onscroll = () => {
                    if(currentIndex <= totalEntryCount){
                        if(currentIndex !== totalEntryCount){
                            setTeachingBubbleVisible(false);
                        }
                    }
                }
            }
            
            if (isInViewport(element) && !teachingBubbleVisible){
                setGuideTourElement(<></>);

                if(timeoutId !== 0)
                    window.clearTimeout(timeoutId);

                setTimeoutId(
                    window.setTimeout(()=>{
                        setGuideTourElement(
                            <CommonGuideTour
                                id="CommonGuideTourDemo"
                                customStyle={{backgroundColor: '#00B7C3'}} 
                                currentEntry={currentEntry} 
                                currentIndex={currentIndex}
                                totalSteps={totalEntryCount} 
                                primaryButtonLabel={primaryButtonLabel} 
                                secondaryButtonLabel={t('customerCenter:CommonGuideTourDialog:tourDefaultButtonText')} 
                                isFooterAction
                                actionKeyword={currentEntry.actionKeyword}
                                onNext={()=>{createGuideTourElement(elementIndex+1)}}
                                onFooterAction={(keyWord:string)=>{
                                    CommonFunctions.logEvent("Click maggiori informazioni tour guidato: "+currentEntry.title,"");
        
                                    if(keyWord !== undefined && keyWord !== "")
                                        outletContext.onOpenGuidePanel(keyWord);
                                    else if(currentEntry.clickable)
                                        outletContext.onOpenGuidePanel(currentEntry.title);
                                }}
                                onCancel={()=>{
                                    CommonFunctions.logEvent("Chiusura del tour guidato", "");
                                    setGuideTourElement(undefined);
                                    guideTourClose = true;
                                    CommonFunctions.enablePageScroll()
                                }} 
                                onDismiss={()=>{
                                    CommonFunctions.logEvent("Chiusura del tour guidato", "");
                                    setGuideTourElement(undefined);
                                    guideTourClose = true;
                                    CommonFunctions.enablePageScroll();
                                }}
                            />
                        );
                    },50)
                );

                setTeachingBubbleVisible(true);
            }
    
            if(currentEntry.clickable !== undefined && currentEntry.clickable){
                let checklistId = document.getElementById(currentEntry.targetId);
    
                if(checklistId !== undefined)
                    checklistId?.click();
            }

            onScrollStop(()=>{
                CommonFunctions.disablePageScroll();
                setTeachingBubbleVisible(true);
            })
        }
        else{
            CommonFunctions.logEvent("Conclusione tour guidato", "");
            CommonFunctions.enablePageScroll();
            outletContext.onHandleContainerStyle(false);
            window.scrollTo({top:0,left:0,behavior:'smooth'});
            setGuideTourElement(undefined);
            setTeachingBubbleVisible(false);
            guideTourClose = true;
        }
    }

    const generateAppChart = (chart: AppChart) : JSX.Element => {
        let field: JSX.Element = <></>;

        switch(chart.chartType){
            case AppChartType.LineChart:
                field = <Stack 
                            className={fieldContainerClassName}
                        >
                            <Line 
                                id={chart.uniqueId}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const,
                                            align:"start" as const
                                        },
                                    }
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
            case AppChartType.AreaChart:
                field = <Stack 
                            className={fieldContainerClassName}
                        >
                            <Line 
                                id={chart.uniqueId}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const,
                                            align:"start" as const
                                        },
                                    }
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
            case AppChartType.DoughnatChart:
                field = <Stack 
                            className={chartDoughnutContainerClassName}
                        >
                            <Doughnut 
                                id={chart.uniqueId}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const
                                        },
                                    }
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
            case AppChartType.HorizontalBar:
                field = <Stack 
                            className={fieldContainerClassName}
                        >
                            <Bar
                                id={chart.uniqueId}
                                options={{
                                    indexAxis: 'y' as const,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const,
                                            align:"start" as const,
                                        },
                                    }
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
            case AppChartType.VerticalBar:
                field = <Stack 
                            className={fieldContainerClassName}
                        >
                            <Bar 
                                id={chart.uniqueId}
                                options={{
                                    indexAxis: 'x' as const,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const,
                                            align:"start" as const,
                                        },
                                    }
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
            case AppChartType.Composed:
                field = <Stack 
                            className={fieldContainerClassName}
                        >
                            <Chart
                                id={chart.uniqueId}
                                type="bar" 
                                options={{
                                    indexAxis: 'x' as const,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const
                                        },
                                        title: {
                                            display: true,
                                            text: chart.chartTitle,
                                            position:"top" as const,
                                            align:"start" as const,
                                        }
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                      },
                                      y: {
                                        stacked: true,
                                      },
                                    },
                            
                                }} 
                                data={chart} 
                            />
                        </Stack>
                break;
                case AppChartType.Funnel:
                    field = <Stack
                                id={chart.uniqueId}
                            >
                                <FunnelChart
                                    showValues={false}
                                    showRunningTotal={false}
                                    data={chart.datasets}
                                />
                            </Stack>
                    break;
        }

        return field;
    }

    const generateImage = (subGroup: AppSubGroup) : JSX.Element => {
        let imageToLink: string = "";
        let field: JSX.Element = <></>;

        const imageContainerClassName =mergeStyles([{
            width:"600px !important",
            height: "calc(100vw - 90%) !important",
            maxWidth: "calc(100vw - 90%) !important",
            maxHeight: "calc(100vw - 90%) !important",
        }]);
        
        if(subGroup.image!.includes("https://") || subGroup.image!.includes("http://")) //Passed image is a link
            imageToLink = subGroup.image!;
        else if(subGroup.image!.includes("data:image/")) //Passed image is a base64 string
            imageToLink = subGroup.image!;
        else{ //Passed image is a local image
            switch(subGroup.image){
                case "pipelineGraph":
                    imageToLink = pipelineGraph;
                    break;
            }
        }

        field =
                <Stack className={imageContainerClassName}>
                    <Image 
                        src={imageToLink} 
                        imageFit={ImageFit.cover} 
                        alt={subGroup.subTitle} 
                        className={imageClassName} 
                        onClick={()=>{
                            CommonFunctions.logEvent("Click immagine: "+subGroup.subTitle,"");
                                    
                            if(subGroup.keyword !== undefined && subGroup.keyword !== "")
                                outletContext.onOpenGuidePanel(subGroup.keyword);
                            else if(subGroup.clickable)
                                outletContext.onOpenGuidePanel(subGroup.subTitle);
                        }}
                    />
                </Stack>
            
        return field;
    }

    const generateRoleCenterContent = () => {
        CommonFunctions.logEvent("Apertura dashboard", "")
        DemoUIHelper.LoadRoleCenterData(HttpHelper.buildAxiosInstance(), sessionInfoContext.currentUser,sessionInfoContext).then((data) => {
            setDataStatus(SystemDataLoadingStatus.Loading);

            generateTrialData(data);
            setDashboardData(data);
            setValidationErrors([]);

            generateRoleCenterLayout();
    
            setDataStatus(SystemDataLoadingStatus.Loaded);
            
            window.scroll(1,1)
            createGuideTourElement(0);

            //#region Shortcut TRIAL

            const shortCutTrial = HttpHelper.getParameter('shortCutTrial');
    
            if((shortCutTrial === 'true') && (shortCutTrial != null)){
                if(data.lead.isTrial > 0){
                    ToastService.showMessage(MessageType.Error,t('demoCenter:Err00002Text'));

                    openVideoModal(
                        "https://youtu.be/uYUwjYTHZ8o&autoplay=true",
                        t('demoRoleCenter:defaultVideoDescription'),
                        true
                    );
                }
                else{
                    canValidateErrors.current = true;
                    setShowTrialModal(true);
                }
                
                window.history.replaceState('', '', window.location.origin+window.location.pathname);
            }
            else
                openVideoModal(
                    "https://youtu.be/uYUwjYTHZ8o&autoplay=true",
                    t('demoRoleCenter:defaultVideoDescription'),
                    true
                );

            //#endregion
        });
    }

    const generateRoleCenterLayout = () => {
        let cueGroupList:JSX.Element[] = [];
        let contentRowList:JSX.Element[] = [];
        let cueGroup:JSX.Element[] = [];
        let subCueGroup:JSX.Element[] = [];
        let field:JSX.Element = <></>;

        outletContext.onHandleContainerStyle(false);

        generateTourData(null,null,null,null,null);

        const currChecklistData: ChecklistItem[] = getDataByLaguage(checkListData);
        setChecklistItems(currChecklistData);

        const appGroupData: AppGroup[] = getDataByLaguage(appRoleCenterData);

        appGroupData.forEach((entry: AppGroup, index: number, array:AppGroup[]) => {

            //Geneate Guide tour entry for main app group
            if(entry.guideTourTitle !== undefined && entry.guideTourDescription !== undefined)
                generateTourData(entry,null,null,null,null);

            entry.subGroup.forEach((subGroup: AppSubGroup) => {
                
                //Geneate Guide tour entry for app sub-group
                if(subGroup.guideTourTitle !== undefined && subGroup.guideTourDescription !== undefined)
                    generateTourData(null,subGroup,null,null,null);
                    
                if(subGroup.chart !== undefined && subGroup.chart.length > 0){
                    //Generate Chart instead of Cue Entry (Green Box)

                    ChartJS.register(CategoryScale,LinearScale,BarElement,Title,ArcElement,Tooltip,Legend,PointElement,LineElement,Filler);

                    subGroup.chart.forEach((chart: AppChart) => {
                
                        //Geneate Guide tour entry for app sub-group
                        if(chart.guideTourTitle !== undefined && chart.guideTourDescription !== undefined)
                            generateTourData(null,null,null,null,chart);

                        field = generateAppChart(chart);

                        cueGroup.push(field);
                    });
                }
                if(subGroup.image !== undefined && subGroup.image !== ""){
                    //Generate Image instead of Cue Entry (Green Box)

                    field = generateImage(subGroup);

                    cueGroup.push(field);
                }
                if(subGroup.cueEntry !== undefined && subGroup.cueEntry.length > 0){
                    //Generate Cue Entry (Green Box)

                    subGroup.cueEntry.forEach((cue: AppEntry) => {

                        //Geneate Guide tour entry for app cue
                        if(cue.guideTourTitle !== undefined && cue.guideTourDescription !== undefined)
                            generateTourData(null,null,cue,null,null);
    
                        field = 
                            <ContentDataBox 
                                name={entry.groupTitle + '-'+ index} 
                                title={cue.cueTitle}
                                iconName=""
                                isIndicatorCue={cue.isIndicatorCue}
                                value={formatValue(cue)}
                                link={cue.redirectUrl}
                                id={cue.uniqueId}
                                extendedTitle={cue.cueExtendedTitle}
                                actionType={cue.actionType}
                                videoId={cue.videoId}
                                severity={cue.indicatorSeverity}
                                onClick={()=>{
                                    CommonFunctions.logEvent("Click contenuto: "+cue.cueTitle,"");
                                    
                                    if(cue.keyword !== undefined && cue.keyword !== "")
                                        outletContext.onOpenGuidePanel(cue.keyword);
                                    else if(cue.clickable)
                                        outletContext.onOpenGuidePanel(cue.cueTitle);
                                }}
                            />
                        
                        cueGroup.push(field);
                    });
                }
                
                //Generate Sub Cue Group (Group of CueBox)
                subCueGroup.push(
                    <SubCueGroup
                        title={subGroup.subTitle}
                        id={subGroup.uniqueId}
                        onClick={()=>{
                            CommonFunctions.logEvent("Click contenuto: "+subGroup.subTitle,"");
                                    
                            if(subGroup.keyword !== undefined && subGroup.keyword !== "")
                                outletContext.onOpenGuidePanel(subGroup.keyword);
                            else if(subGroup.clickable)
                                outletContext.onOpenGuidePanel(subGroup.subTitle);
                        }}
                    >
                        {cueGroup}
                    </SubCueGroup>
                )

                cueGroup = [];
            });

            //Generate App Group (Group of SubCueGroup)
            cueGroupList.push(
                <CueGroup 
                    name={"CueGroup-"+index}
                    title={entry.groupTitle}
                    id={entry.uniqueId}
                    extendedTitle={entry.extendedTitle}
                    onClick={()=>{
                        CommonFunctions.logEvent("Click contenuto: "+entry.groupTitle,"");
                                    
                        if(entry.keyword !== undefined && entry.keyword !== "")
                            outletContext.onOpenGuidePanel(entry.keyword);
                        else if(entry.clickable)
                            outletContext.onOpenGuidePanel(entry.groupTitle);
                    }}
                >
                    {subCueGroup}
                </CueGroup>
            )
            
            if((index % 2 === 0) || (index === array.length-1)){
                contentRowList.push(
                    <RoleCenterContentRow 
                        id={"RW-"+index}
                    >
                        {cueGroupList}
                    </RoleCenterContentRow>
                )

                cueGroupList = [];
            }
            subCueGroup = [];
        });

        //Generate Guide tour entry for checklist items
        currChecklistData.forEach((entry: ChecklistItem)=>{
            if(entry.guideTourTitle !== undefined && entry.guideTourDescription !== undefined)
                generateTourData(null,null,null,entry,null);
        });

        setRoleCenterContent(contentRowList);
    }

    const generateTourData = (appGroup: AppGroup | null,appSubGroup:AppSubGroup | null,appEntry:AppEntry | null,checkListEntry:ChecklistItem | null,chartEntry:AppChart | null) =>{
        let totalTourEntry: ITourEntry[] = [];

        totalTourEntry = guideTourEntry;

        if(appGroup !== null){
            totalTourEntry.push( 
                {
                    title: appGroup.guideTourTitle!,
                    description: appGroup.guideTourDescription!,
                    targetId: appGroup.uniqueId,
                    direction:DirectionalHint.topAutoEdge,
                    adminRequired:false,
                    canScroll:true,
                    clickable: appGroup.clickable ? true : false,
                    actionKeyword: appGroup.keyword
                }
            );
        }
        else if(appSubGroup !== null){
            totalTourEntry.push( 
                {
                    title: appSubGroup.guideTourTitle!,
                    description: appSubGroup.guideTourDescription!,
                    targetId: appSubGroup.uniqueId,
                    direction:DirectionalHint.topAutoEdge,
                    adminRequired:false,
                    canScroll:true,
                    clickable: appSubGroup.clickable ? true : false,
                    actionKeyword: appSubGroup.keyword
                }
            );
        }
        else if(appEntry !== null){
            totalTourEntry.push( 
                {
                    title: appEntry.guideTourTitle!,
                    description: appEntry.guideTourDescription!,
                    targetId: appEntry.uniqueId,
                    direction:DirectionalHint.topAutoEdge,
                    adminRequired:false,
                    canScroll:true,
                    clickable: appEntry.clickable ? true : false,
                    actionKeyword: appEntry.keyword
                }
            );
        }
        else if(checkListEntry !== null){
            totalTourEntry.push( 
                {
                    title: checkListEntry.guideTourTitle!,
                    description: checkListEntry.guideTourDescription!,
                    targetId: checkListEntry.id,
                    direction:DirectionalHint.leftCenter,
                    adminRequired:false,
                    canScroll:true,
                    clickable: checkListEntry.clickable ? true : false,
                    actionKeyword: ""
                }
            );
        }
        else if(chartEntry !== null){
            totalTourEntry.push( 
                {
                    title: chartEntry.guideTourTitle!,
                    description: chartEntry.guideTourDescription!,
                    targetId: chartEntry.uniqueId,
                    direction:DirectionalHint.topAutoEdge,
                    adminRequired:false,
                    canScroll:true,
                    clickable: chartEntry.clickable ? true : false,
                    actionKeyword: ""
                }
            );
        }
        else{
            totalTourEntry.push( 
                {
                    title: t("demoRoleCenter:banner:gtTitle")!,
                    description: "",
                    targetId: "gtBannerContent",
                    direction:DirectionalHint.rightCenter,
                    adminRequired:false,
                    canScroll:true,
                    clickable: false,
                    actionKeyword: ""
                }
            );
        }

        setGuideTourEntry(totalTourEntry);
    }

    const generateTrialData = (data:IDemoDashboardData) => {
        let ecommerceCustomer: ECommerceCustomer = new ECommerceCustomer();
        let ecommerceUser: ECommerceUser = new ECommerceUser();
        let customerUser: CustomerUser = new CustomerUser();

        customerUser.customer = ecommerceCustomer;
        customerUser.user = ecommerceUser;
        customerUser.user.firstName = sessionInfoContext.currentUser.firstName;
        customerUser.user.lastName = sessionInfoContext.currentUser.lastName;
        customerUser.user.privateEmail = sessionInfoContext.currentUser.email;
        customerUser.user.leadId = sessionInfoContext.currentUser.referenceId;

        setTrialData({
            confirmPassword: "",
            currentLead: data.lead,
            customerUser: customerUser  
        });
    }

    const getDataByLaguage = (data:any[]) : any[] => {
        let availableLanguage: string[] = [];
        let currentLanguage: string = sessionInfoContext.currentUserSettings.userLanguageCode;
        let currentLanguageEntry: any;

        //Get available languages
        data.forEach((entry:any)=>{
            availableLanguage.push(entry.locale);
        })

        //Check fallback language
        if(!availableLanguage.includes("EN"))
            return [];

        //Check if current language is available
        if(availableLanguage.includes(currentLanguage)){
            currentLanguageEntry = data.find(currentEntry => currentEntry.locale === currentLanguage);
            defaultCurrecySymbol = currentLanguageEntry.currencySymbol;

            return currentLanguageEntry?.entry!;
        }
        else{
            currentLanguageEntry = data.find(currentEntry => currentEntry.locale === "EN");
            defaultCurrecySymbol = currentLanguageEntry.currencySymbol;

            return currentLanguageEntry?.entry!;
        }
    }

    const onScrollStop = (callback:any) => {
        let isScrolling:any;

        window.addEventListener(
            'scroll',
            event => {
                window.clearTimeout(isScrolling);
                
                if(guideTourClose)
                    window.removeEventListener('scroll', ()=>{window.clearTimeout(isScrolling);});
                else {
                    isScrolling = window.setTimeout(() => {
                        callback();
                    }, 100)
                };
            },
            false
        );
    };
    
    const openVideoModal = (content:string,description:string,disableLight:boolean) => {
        setDisableVideoLight(false);
        
        ContentManagement.generateResource(btoa(content),description).then((resp: IContentManagement)=>{

            if(disableLight)
                setDisableVideoLight(true);

            setVideoResource(resp);
            
            setShowVideoModal(true);
        })
    }

    const formatValue = (appEntry: AppEntry):string => {
        
        switch(appEntry.indicatorType) {
            case AppIndicatorType.Percentage:
                return appEntry.value + '%';
            case AppIndicatorType.Count:
                return appEntry.value.toString();
            case AppIndicatorType.Cost:
                return appEntry.value + defaultCurrecySymbol;
            default:
                return appEntry.value.toString();
        }
    }

    const handleCheckListStep = (itemId: string) => {

        setLoaderDataStatus(SystemDataLoadingStatus.Loading);

        switch(itemId){
            case 'INIT':
                CommonFunctions.logEvent("Apertura video: Come funziona", "");
                openVideoModal(
                    "https://youtu.be/uYUwjYTHZ8o&autoplay=true",
                    t('demoRoleCenter:defaultVideoDescription'),
                    true
                );
                break;
            case 'BOOKING':
                CommonFunctions.logEvent("Apertura form booking per prenotazione consulente", "");
                setChecklistCookie(itemId);
                setShowBookingModal(true);
                break;
            case 'TRIAL':
                //analytics.sendEvent('attiva_trial', {});
                //checkLeadTrialStatus(itemId);
                window.location.replace(sessionInfoContext.currentApp.homepageUrl);
                break;
            case 'SHOP':
                CommonFunctions.logEvent("Apertura shop", "");
                setChecklistCookie(itemId);

                if(window.location.hostname.includes('uat-'))
                    window.open("https://uat-shop.so-smart.it/","_blank");
                else if(window.location.hostname.includes('localhost'))    
                    window.open("https://uat-shop.so-smart.it/","_blank");
                
                setForceUpdate(!forceUpdate);
                break;
        }

        setLoaderDataStatus(SystemDataLoadingStatus.Loaded);
    }

    const isInViewport = (element:HTMLElement | null) => {
        if(element === null)
            return false;

        let rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const onSaveTrialModalRequest = () => {
        let lead = trialData.currentLead;
        delete (lead as any).timestamp;
        trialData.currentLead = lead;
        
        setSaveInProgress(true);
        trialData.customerUser.customer.isTrial = CustomerTrialStatus.On;
        DemoUIHelper.createTrialCustomerUser(trialData, HttpHelper.buildAxiosInstance()).then((response) => {
            if (response.validationErrors.length > 0){
                setValidationErrors(response.validationErrors);
                setSaveInProgress(false);
            }
            else
            {
                setValidationErrors([]);
                setSaveInProgress(false);

                ToastService.showMessage(MessageType.Success, t('demoCenter:Text00001Lbl'));

                CommonFunctions.logEvent("Conferma prova gratuita", "");
                let core: SystemCore = new SystemCore(); 
                window.location.replace((process.env.REACT_APP_CUSTOMER_PORTAL_ADDRESS + btoa(core.getUserToken())));
            }
        });
    }

    const setChecklistCookie = (itemId: string) => {
        let cookie = CookieService.getCustomCookie('CHECKLIST');
        if(!cookie?.includes(itemId)!)
            CookieService.setCustomCookie('CHECKLIST',cookie + ',' + itemId);
    }

    const tryOpenDemoWizard = () => {
        DemoUIHelper.getLeadQualifiedAnswer(trialData.currentLead).then((resp:LeadQualifiedAnswer[])=>{
            if(resp.length === 0){
                if(trialData.currentLead.budget == null || trialData.currentLead.budget === 0 || 
                    trialData.currentLead.expectedLiveDate.toString() === '0001-01-01T00:00:00' || 
                    trialData.currentLead.decisionDate.toString() === '0001-01-01T00:00:00')
                     setDemoWizardVisible(true);
            }
        })
    }

    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            generateRoleCenterContent();
            analytics.initialize();
            analytics.sendPageViewLog();
        }
    }, [dataStatus]);

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t("demoRoleCenter:loadingDescription")!} />
    else
    {
        return (
            <>
                <Stack className={RoleCenterContainerClassName}>
                    <Banner
                        text={t("demoRoleCenter:banner:title")} 
                        hasSubtext 
                        subText={t("demoRoleCenter:banner:subTitle")!}
                    >
                        {
                            loaderDataStatus === SystemDataLoadingStatus.Loaded || 
                            loaderDataStatus === SystemDataLoadingStatus.ToLoad ?
                                <Checklist 
                                    items={checkListItems} 
                                    onCancel={()=>{}} 
                                    onStart={(item:ChecklistItem)=>handleCheckListStep(item.id)}
                                />
                            :
                                <ChecklistLoader />
                        }
                    </Banner>
                        {roleCenterContent}
                </Stack>
                <TrialModal 
                    industryTypes={dashboardData.industryTypes}
                    showModal={showTrialModal}
                    savingData={saveInProgress}
                    trialData={trialData}
                    validationErrors={validationErrors}
                    onChange={(data:TrialData)=>{
                        setTrialData(data);
                        if(canValidateErrors.current)
                            setValidationErrors(DemoUIHelper.checkTrialModalData(data));
                    }}
                    onCancel={()=>{
                        setShowTrialModal(false);
                        canValidateErrors.current = false;
                        
                        CommonFunctions.logEvent("Chiusura form trial senza conferma", "");
                    }}
                    onSave={onSaveTrialModalRequest}
                    currentLead={trialData.currentLead}
                />
                <RoleCenterBookingModal
                    showModal={showBookingModal}
                    onDismiss={()=>{
                        CommonFunctions.logEvent("Chiusura form booking per prenotazione consulente", "");
                        setShowBookingModal(false)
                    }}
                />
                <CommonVideoModal 
                    showModal={showVideoModal}
                    disableLight={disableVideoLight} 
                    key={"DemoVideoModal-" + videoResource?.description} 
                    onDismiss={(isVideoPlayed:boolean)=>{
                        if(isVideoPlayed)
                            CommonFunctions.logEvent("Chiusura video dialog: "+videoResource?.description, "");
                        else
                            CommonFunctions.logEvent("Chiusura video dialog: "+videoResource?.description+", senza visione del contenuto", "");
                        
                        setShowVideoModal(false);
                        //tryOpenDemoWizard();
                    }} 
                    resource={videoResource!} 
                />
                <RoleCenterWizardModal 
                    onDismiss={()=>{setDemoWizardVisible(false)}}
                    showModal={demoWizardVisible}
                />
                {teachingBubbleVisible && guideTourElement}
            </>
        );
    }
}
