import { useContext } from "react";
import PageContainer from "../../Common/Template/PageContainer";
import { SystemPageType } from "../../../Model/SystemModels";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { useNavigate } from "react-router-dom";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { t } from "i18next";

export const PartnerCustomerSubscriptionDetailList : React.FC = () => {
    const navigate = useNavigate();
    const partnerInfoContext = useContext(PartnerInformationContext);
    
    let listTitle = t('partnerCenter:partnerCustomerSubscriptionLineList:title')!;
     
    const buildEndpointList = () : string => {
        const subscriptionId = HttpHelper.getParameter("subscriptionId");
        const customerId = HttpHelper.getParameter("customerId");
        const companyName = HttpHelper.getParameter("name");

        if (!subscriptionId || !customerId || !companyName)
            navigate("/app/partner/home");

        listTitle += companyName;

        return "/odata/subscription/line?$filter=idSubscriptionHeader eq "+subscriptionId+" and partnerId eq "+partnerInfoContext.partner.id+" and customerId eq "+customerId;
    }
    
    return (
        <>
            <PageContainer endpoint={buildEndpointList()} pageType={SystemPageType.List} title={listTitle} backAllowed>                            
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:id')!} fieldName="id" minWidth={60} maxWidth={80} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:documentNo')!} fieldName="documentNo" minWidth={250} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:documentLineNo')!} fieldName="documentLineNo" minWidth={250} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:description')!} fieldName="description" minWidth={500} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:quantity')!} fieldName="quantity" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:price')!} fieldName="price" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:lineAmount')!} fieldName="lineAmount" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:discount_Percentage')!} fieldName="discount_Percentage" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:status')!} fieldName="status" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:recurringInterval')!} fieldName="recurringInterval" minWidth={130} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:startDate')!} fieldName="startDate" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionLineList:fields:endDate')!} fieldName="endDate" minWidth={150} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}
