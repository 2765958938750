import ReactGA from "react-ga4";

const debugMode: boolean = window.location.hostname.includes('uat-') || window.location.hostname.includes('localhost');

const options = {
    testMode: debugMode
};

export class googleAnalyticsManagement {
    
    constructor() {}

    public initialize() {
        ReactGA.initialize([
            {
                trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG!,
                gaOptions: {
                  debug_mode: debugMode,
                  cookieFlags: 'SameSite=Strict;Secure'
                },
                gtagOptions: {
                  debug_mode: debugMode
                }
            }
        ], options);
    }

    public sendEvent(eventName: string, params: Object) {
        ReactGA.event(eventName, params);
    }

    public sendPageViewLog() {
        ReactGA.send({ 
            hitType: "pageview", 
            page: window.location.pathname, 
            title: document.title 
        });
    }
}