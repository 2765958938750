import { Checkbox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, IModalProps, PrimaryButton, Stack, TextField, Separator, ChoiceGroup, IChoiceGroupOption, TooltipHost, Label, IconButton, ShimmeredDetailsList, SelectionMode, IColumn, ResponsiveMode, MessageBar, MessageBarType, VirtualizedComboBox, Spinner } from "@fluentui/react"
import { t } from "i18next";
import { Lead } from "../../../Model/PartnerModels";
import { PropsWithChildren, useEffect, useState } from "react";
import { PostalCode, SystemDataLoadingStatus, SystemFieldType } from "../../../Model/SystemModels";
import React from "react";
import { IndustyType, LeadIndustry } from "../../../Model/DemoModels";
import { CrmLostRiskLine, LeadConfigChecklistDetails, LeadToLiveChecklist, SaveLeadConfigChecklist } from "../LeadDashboard/LeadDashboard";
import { AxiosInstance } from "axios";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { CountryRegion } from "../../../Model/CustomerModels";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { BalanceSheet, OpenAPICompanyITAdvancedData } from "../../../Model/OpenAPIModel";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";

//#region Lead Modal Content

export const CommonLeadModalContent: React.FC<PropsWithChildren<{}>> = (props) =>{

    return(
        <Stack
            tokens={{childrenGap: 5}}
            styles={{
                root: {
                    width: '100%',
                    padding: 5,
                    height: '65vh',
                    overflow: 'auto'
                }
            }}
        >
            {props.children}
        </Stack>
    )
}

//#endregion

//#region Lead Modal Group

export interface ICommonLedModalGroupProps {
    title: string,
    subtitle?: string,
    id: string
}

export const CommonLeadModalGroup: React.FC<PropsWithChildren<ICommonLedModalGroupProps>> = (props) => {
    const [hidden, setHidden] = useState<boolean>(false);

    return (
        <Stack
            verticalAlign="baseline"
            verticalFill
            styles={{
                root: {
                    width: '100%',
                    height: 'auto'
                }
            }}
        >
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalFill
                verticalAlign="center"
                styles={{
                    root: {
                        backgroundColor: '#F2F2F2F2',
                        marginBottom: 5,
                        height: 50,
                        padding: 5
                    }
                }}
            >
                <Stack
                    horizontal
                    verticalFill
                    verticalAlign="center"
                    horizontalAlign="start"

                    styles={{
                        root:{
                            position: 'relative'
                        }
                    }}
                >
                    <h3
                        style={{
                            fontWeight: 500,
                            position: 'relative',
                            bottom: "1px"
                        }}
                    >
                        {props.title}
                    </h3>
                    <Stack.Item
                        id={props.id}
                    >
                        <TooltipHost
                            content={props.subtitle}
                            id={"tootlip-inner-"+props.id}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{root:{display:'inline-block !important'}}}
                        >
                            <IconButton id={"tootlip-"+props.id} iconProps={{iconName: 'Info'}} />
                        </TooltipHost>
                    </Stack.Item>
                </Stack>
                <Stack.Item>
                    {
                        hidden ?
                                <IconButton onClick={() => setHidden(!hidden)} iconProps={{iconName: 'ChevronRight'}} />
                            :
                                <IconButton onClick={() => setHidden(!hidden)} iconProps={{iconName: 'ChevronDown'}} />
                    }
                </Stack.Item>
            </Stack>
            <Stack
                styles={{
                    root: {
                        display: hidden ? 'none' : 'block',
                        padding: 10
                    }
                }}
            >
                <Stack.Item>
                    {props.children}
                </Stack.Item>
            </Stack>
        </Stack>
    )
}

//#endregion

//#region Lead Modal Row

export const CommonLeadModalRow: React.FC<PropsWithChildren<{}>> = (props) =>{

    return(
        <Stack
            horizontal
            horizontalAlign="space-between"
            wrap
            styles={{
                root: {
                    width: '100%'
                }
            }}
        >
            {props.children}
        </Stack>
    )
}

//#endregion

//#region Lead Modal Field

export interface ILeadModalFieldProps {
    fieldName: string,
    label?: string,
    value: any,
    defaultValue?: string,
    mandatory: boolean,
    tabIndex?: number,
    disabled: boolean,
    placeholder?: string,
    fieldType: SystemFieldType,
    cmbOptions?: IComboBoxOption[],
    radioOptions?: IChoiceGroupOption[],
    errorMessage?: string,
    maxLength?: number,
    hasTableRelation?: boolean;
    tableRelationEndpoint?: string;
    tableRelationKey?: string;
    tableRelationDescription?: string;
    minValue?: number;
    maxValue?: number;
    onChange: (newValue:any,key:string) => void;
    onValidate?: (newValue: any) => void;
}

export const CommonLeadModalField: React.FC<ILeadModalFieldProps> = (props) =>{
    const [tableRelationOption, setTableRelationOption] = useState<IComboBoxOption[]>([]);
    const [tableRelationLoading, setTableRelationLoading] = useState<boolean>(true);

    const formatDate = (dateStr?: string): Date | undefined => {
        if (dateStr != undefined)
        {
            let convert: Date = new Date(dateStr);
            if (convert.getFullYear() == 1)
                return undefined;
                
            return convert;
        }

        return undefined;
    }

    const processTableRelation = () => {
        let client: AxiosInstance = HttpHelper.buildAxiosInstance();

        setTableRelationLoading(true);
        client.get(props.tableRelationEndpoint!).then((resp) => {
            setTableRelationLoading(false);
            let options: IComboBoxOption[] = [];
            resp.data.forEach((item: any) => {
                options.push({
                    key: item[props.tableRelationKey!],
                    text: item[props.tableRelationDescription!]
                })
            });

            setTableRelationOption(options);
            setTableRelationLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    switch(props.fieldType){
        case SystemFieldType.String:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                    <TextField
                        key={props.fieldName} 
                        label={props.label}
                        required={props.mandatory}
                        onChange={(e, value) => {
                            props.onChange(value,props.fieldName);
                        }}
                        value={props.value}
                        errorMessage={props.errorMessage}
                        disabled={props.disabled}
                        maxLength={props.maxLength}
                        placeholder={props.placeholder}
                        onBlur={() => {
                            if (props.onValidate)
                                props.onValidate(props.value);
                        }}
                    />
                </Stack.Item>
            )
        case SystemFieldType.Option:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                {
                    !props.hasTableRelation ?                   
                        <VirtualizedComboBox
                            defaultSelectedKey={props.defaultValue}
                            label={props.label}
                            required={props.mandatory}
                            styles={{
                                callout:{
                                    maxWidth: '100%'
                                },
                                optionsContainer:{
                                    maxHeight: 300,
                                    overflowY: 'auto'
                                }
                            }}
                            selectedKey={props.value}
                            options={props.cmbOptions!}
                            errorMessage={props.errorMessage}
                            calloutProps={{ doNotLayer: false}}
                            disabled={props.disabled}
                            useComboBoxAsMenuWidth 
                            onChange={(ev, option, index, value) => {
                                props.onChange(option?.key, props.fieldName);
                            }}
                            autoComplete="on"
                            dropdownMaxWidth={200}
                        />
                    :
                        <VirtualizedComboBox 
                            label={props.label} 
                            useComboBoxAsMenuWidth 
                            onRenderLowerContent={() => {
                                if (props.hasTableRelation && tableRelationLoading)
                                {
                                    processTableRelation();
                                    return (
                                        <Spinner label="Stiamo caricando i dati..." ariaLive="assertive" labelPosition="right" style={{
                                            padding: 10,
                                            width: '200px'
                                        }} />
                                    );
                                }

                                return <></>;
                            }} 
                            errorMessage={props.errorMessage}
                            options={tableRelationOption}
                            selectedKey={props.value} 
                            defaultSelectedKey={props.defaultValue}
                            onChange={(ev, option, index, value) => {
                                props.onChange(option?.key, props.fieldName);
                            }} 
                            disabled={props.disabled} 
                        />
                }
                </Stack.Item>
            )
        case SystemFieldType.Radio:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                    <ChoiceGroup 
                        label={props.label}
                        styles={{
                            flexContainer:{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                flexWrap: 'wrap'
                            }
                        }}
                        required={props.mandatory}
                        selectedKey={props.value.toString()}
                        options={props.radioOptions}
                        disabled={props.disabled}
                        onChange={(ev, option) => {
                            props.onChange(option?.key, props.fieldName);
                        }}
                    />
                    <Label
                        styles={{
                            root: {
                                color: 'rgb(164, 38, 44)',
                                fontSize: '12px',
                                fontWeight: '400'
                            }
                        }}
                        hidden={!props.errorMessage}>
                        {props.errorMessage}
                    </Label>
                </Stack.Item>
            )
        case SystemFieldType.Number:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                    <TextField
                        type="number"
                        key={props.fieldName} 
                        label={props.label}
                        required={props.mandatory}
                        onChange={(e, value) => {
                            props.onChange(value,props.fieldName);
                        }}
                        value={props.value}
                        errorMessage={props.errorMessage}
                        disabled={props.disabled}
                        maxLength={props.maxLength}
                        min={props.minValue}
                        max={props.maxValue}
                    />
                </Stack.Item>
            )
        case SystemFieldType.Date:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                    <DatePicker
                        placeholder={props.placeholder}
                        label={props.label}
                        value={formatDate(props.value)}
                        formatDate={(date) => {
                            let days, month, year;
        
                            if (date != undefined)
                            {
                                days = date.getDate();
                                month = date.getMonth() + 1;
                                year = date.getFullYear();
                                return days + "/" + month + "/" + year;
                            }
        
                            return "";
                        }}
                        onSelectDate={(date) => {
                            date!.setHours(date!.getHours() + 3);
                            props.onChange(date, props.fieldName);
                        }}
                        disabled={props.disabled}
                    />
                </Stack.Item>
            )
        case SystemFieldType.Boolean:
            return(
                <Stack.Item
                    styles={{
                        root: {
                            width: '45%',
                            minWidth: '250px'
                        }
                    }}
                >
                    <Checkbox 
                        key={props.fieldName}
                        label={props.label}
                        required={props.mandatory}
                        styles={{root:{
                            paddingTop: 30,
                            paddingBottom: 11
                        }}}
                        checked={props.value}
                        onChange={(ev, checked) => {
                            props.onChange(checked, props.fieldName);
                        }}
                    />
                </Stack.Item>
            )
        default:
            return <></>
    }
}

//#endregion

//#region Lead Modal Selection List

export class LeadQuoteDetails {
    public itemId: string = "";
    public description: string = '';
    public notes: string = '';
    public selected: boolean = false;
}

export class MigrationLostRiskLineDetail {
    public id: number = 0;
    public description: string = '';
    public notes: string = '';
    public selected: boolean = false;
}

export class TimingListItem {
    public id: number = 0;
    public title: string = '';
    public promo: string = "";
    public operatingMode: string = '';
    public suggestedTo: string = "";
    public selected: boolean = false;
    public highlited?: boolean = false;
}

export interface ICommonLeadModalSelectionListProps {
    id?: string,
    items: any[],
    columns: IColumn[],
    errorMessage?: string
}

export const CommonLeadModalSelectionList: React.FC<ICommonLeadModalSelectionListProps> = (props) => {

    return (
        <Stack
            styles={{
                root: {
                    width: '100%'
                }
            }}
            verticalFill
            verticalAlign="space-between"
            id={props.id}
        >
            <ShimmeredDetailsList items={props.items}
                columns={props.columns}
                selectionMode={SelectionMode.none}
            />
            <Label
                styles={{
                    root: {
                        color: 'rgb(164, 38, 44)',
                        fontSize: '12px',
                        fontWeight: '400'
                    }
                }}
                hidden={!props.errorMessage}>
                {props.errorMessage}
            </Label>
        </Stack>
    );
}

//#endregion

export interface ILeadModalProps {
    showModal: boolean;
    savingData: boolean;
    leadRef: Lead;
    leadToLiveChecklist: LeadToLiveChecklist;
    leadConfigDetail: LeadConfigChecklistDetails;
    industryTypes: IndustyType[];
    validationErrors: Object[];
    canScroll: boolean;
    onSave: (configuration: SaveLeadConfigChecklist) => boolean;
    onCancel: () => void;
    checkLeadData: (configurationCheck: SaveLeadConfigChecklist) => void;
}

export const CommonLeadModal : React.FC<ILeadModalProps> = (props) => {
    const [configuration, setConfiguration] = useState<SaveLeadConfigChecklist>(new SaveLeadConfigChecklist());
    const [countryOptions, setCountryOptions] = useState<IComboBoxOption[]>([]);
    const [cityOptions, setCityOptions] = useState<IComboBoxOption[]>([]);
    const [countyOptions, setCountyOptions] = useState<IComboBoxOption[]>([]);
    const [postalCodeData, setPostalCodeData] = useState<PostalCode[]>([]);
    const [industryOptions, setIndustryOptions] = useState<IComboBoxOption[]>([]);
    const [timingListIntems, setTimingListItems] = useState<TimingListItem[]>([]);
    const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);
    const [businessNeedDisabled, setbusinessNeedDisabled] = useState(false);
    const [dialogDataStatus, setDialogDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [validatingVat, setValidatingVat] = useState<boolean>(false);
    const [dataInitialized, setDataInitialized] = useState<boolean>(false);

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: t('common:qualification:Text0044Lbl')
    };
    
    const modalProps: Partial<IModalProps> = {
        isBlocking: true,
        responsiveMode: ResponsiveMode.medium,
        layerProps:{
            onLayerDidMount: () => {
                if(props.canScroll){
                    //Handle scroll request
                    const businessNeedsPart = document.getElementById('businessNeedsKey');
                    console.log(businessNeedsPart);
                    if (businessNeedsPart !== null)
                        businessNeedsPart.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"});
                }
            }
        }
    };

    const handleCountryValue = (): string | undefined => {
        return configuration.lead.country !== null && configuration.lead.country !== "" ? configuration.lead.country : undefined;
    }

    const suggestCountry = (configuration: SaveLeadConfigChecklist): string => {
        if((configuration.lead.country !== "") && (configuration.lead.country !== null)){
            return configuration.lead.country;
        }

        let hostname: string = window.location.hostname;
        let extension: string | undefined = "";
        if(window.location.hostname.includes('localhost')){
            extension = process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_HOST_EXTENSION?.substring(1).toUpperCase();
        }
        else{
            extension = hostname.split('.')[2].toUpperCase();
        }

        if(extension !== undefined){
            getPostalCodes(extension, false);
            return extension;
        }

        return "";
    }

    const initCountryRegionData = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            let client: AxiosInstance = HttpHelper.buildAxiosInstance();
            client.get<CountryRegion[]>('/odata/CountryRegion?$filter=enabled eq true').then((resp) => {
                let options: IComboBoxOption[] = [];
                resp.data.forEach((value: CountryRegion, index: number, array: CountryRegion[]) => {
                    options.push({
                        key: value.countryCode,
                        text: value.country
                    })
                });
                setCountryOptions(options);
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data.message);
            });
        });
    }

    const getPostalCodes = (countryCode: string, clearPostalCode: boolean) => {
        setCityOptions([]);
        setCountyOptions([]);

        if(clearPostalCode){
            validateLocationData('');
        }
        
        const client = HttpHelper.buildAxiosInstance();
        client.get<PostalCode[]>(`/odata/PostalCode?$filter=countryCode eq '${countryCode}'`).then((resp) => {
            setPostalCodeData(resp.data);
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.response.data.message);
        });
    }

    const validateLocationData = (postalCode: string) => {
        let cityOptions: IComboBoxOption[] = [];
        let countyOptions: IComboBoxOption[] = [];
        let currentLead: Lead = configuration.lead;
        let currConfiguration: SaveLeadConfigChecklist = configuration;

        currentLead.zipCode = postalCode;
        currentLead.city = "";
        currentLead.county = "";

        if(postalCode !== ""){
            let postalCodeOptions: PostalCode[] = postalCodeData.filter(item => item.zipCode == postalCode);
            postalCodeOptions.forEach((value: PostalCode, index: number, array: PostalCode[]) => {
                if(countyOptions.findIndex(item => item.key == value.county) < 0){
                    countyOptions.push({
                        key: value.county,
                        text: value.county
                    });
                }
    
                cityOptions.push({
                    key: value.city,
                    text: value.city
                });
            })
    
            if(cityOptions.length == 1){
                currentLead.city = cityOptions[0].key.toString();
            }
            if(countyOptions.length == 1){
                currentLead.county = countyOptions[0].key.toString();
            }
        }

        currConfiguration.lead = currentLead;
        setConfiguration(currConfiguration);
        props.checkLeadData(currConfiguration);

        setCityOptions(cityOptions);
        setCountyOptions(countyOptions);
    }

    const getFieldErrorMessage = (fieldName: string) : string => {
        let result = props.validationErrors.filter((e: any) => e.reference == fieldName && e.applyToField);
        if (result.length > 0)
        {
            let errorObject: any = result[0];
            return errorObject.error;
        }

        return "";
    }

    const handleBusinessNeedsLostRiskPropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentLostRiskLine: CrmLostRiskLine;
        if (configuration.details.businessNeedsRiskLines[index] === undefined)
            currentLostRiskLine = new CrmLostRiskLine();
        else
            currentLostRiskLine = configuration.details.businessNeedsRiskLines[index];

        let currentLostRiskLineList: CrmLostRiskLine[] =  props.leadConfigDetail.businessNeedsRiskLines;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentLostRiskLine.lossCauseCode = 'BUSINESS_NEEDS';
        currentLostRiskLine.sourceTable = 'Lead';
        currentLostRiskLine.sourceId = props.leadRef.id;
        currentLostRiskLine = Object.defineProperty(
            currentLostRiskLine, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentLostRiskLineList[index] = currentLostRiskLine;
        currConfiguration.details.businessNeedsRiskLines = currentLostRiskLineList;
        setConfiguration(currConfiguration);
        props.checkLeadData(currConfiguration);
    }

    const handleBusinessNeedsNotesLostRiskPropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentLostRiskLine: CrmLostRiskLine;
        if (configuration.details.businessNeedsNotesRiskLines[index] === undefined)
            currentLostRiskLine = new CrmLostRiskLine();
        else
            currentLostRiskLine = configuration.details.businessNeedsNotesRiskLines[index];

        let currentLostRiskLineList: CrmLostRiskLine[] =  props.leadConfigDetail.businessNeedsNotesRiskLines;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentLostRiskLine.lossCauseCode = 'BUSINESS_NEEDS_NOTE';
        currentLostRiskLine.sourceTable = 'Lead';
        currentLostRiskLine.sourceId = props.leadRef.id;
        currentLostRiskLine = Object.defineProperty(
            currentLostRiskLine, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentLostRiskLineList[index] = currentLostRiskLine;
        currConfiguration.details.businessNeedsNotesRiskLines = currentLostRiskLineList;
        setConfiguration(currConfiguration);
    }

    const handleGapLostRiskPropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentLostRiskLine: CrmLostRiskLine;
        if (configuration.details.gapRiskLines[index] === undefined)
            currentLostRiskLine = new CrmLostRiskLine();
        else
            currentLostRiskLine = configuration.details.gapRiskLines[index];

        let currentLostRiskLineList: CrmLostRiskLine[] =  props.leadConfigDetail.gapRiskLines;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentLostRiskLine.lossCauseCode = 'GAP';
        currentLostRiskLine.sourceTable = 'Lead';
        currentLostRiskLine.sourceId = props.leadRef.id;
        currentLostRiskLine = Object.defineProperty(
            currentLostRiskLine, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentLostRiskLineList[index] = currentLostRiskLine;
        currConfiguration.details.gapRiskLines = currentLostRiskLineList;
        setConfiguration(currConfiguration);
    }

    const handleLeadIndustryPropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentIndustry: LeadIndustry;
        if (configuration.details.leadIndustries[index] === undefined)
            currentIndustry = new LeadIndustry();
        else
            currentIndustry = configuration.details.leadIndustries[index];

        let currentIndustryList: LeadIndustry[] =  configuration.details.leadIndustries;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentIndustry.leadId = props.leadRef.id;
        currentIndustry = Object.defineProperty(
            currentIndustry, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentIndustryList[index] = currentIndustry;
        currConfiguration.details.leadIndustries = currentIndustryList;
        setConfiguration(currConfiguration);
    }

    const handleLeadMigrationPropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentMigration: MigrationLostRiskLineDetail;
        if (configuration.details.migrationRiskLines[index] === undefined)
            currentMigration = new MigrationLostRiskLineDetail();
        else
            currentMigration = configuration.details.migrationRiskLines[index];

        let currentMigrationList: MigrationLostRiskLineDetail[] =  configuration.details.migrationRiskLines;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentMigration = Object.defineProperty(
            currentMigration, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentMigrationList[index] = currentMigration;
        currConfiguration.details.migrationRiskLines = currentMigrationList;
        setConfiguration(currConfiguration);
    }

    const handleLeadQuotePropertyChange = (fieldName: string, newValue: any, index: number) => {
        let currentQuoteDetail: LeadQuoteDetails;
        if (configuration.details.leadQuoteDetails[index] === undefined)
            currentQuoteDetail = new LeadQuoteDetails();
        else
            currentQuoteDetail = configuration.details.leadQuoteDetails[index];

        let currentQuoteDetailList: LeadQuoteDetails[] =  configuration.details.leadQuoteDetails;
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        currentQuoteDetail = Object.defineProperty(
            currentQuoteDetail, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )

        currentQuoteDetailList[index] = currentQuoteDetail;
        currConfiguration.details.leadQuoteDetails = currentQuoteDetailList;
        setConfiguration(currConfiguration);
    }

    const handleLeadPropertyChange = (fieldName: string, newValue: any) => {
        let lead: Lead = JSON.parse(JSON.stringify(configuration.lead));
        let currConfiguration: SaveLeadConfigChecklist = JSON.parse(JSON.stringify(configuration));

        lead = Object.defineProperty(
            lead, 
            fieldName, 
            {
                value: newValue!,
                writable: true,
                enumerable: true,
            }
        )
        
        currConfiguration.lead = lead;
        setConfiguration(currConfiguration);
        props.checkLeadData(currConfiguration);
    }

    const initCrmLostRiskLines = (riskCode: string): CrmLostRiskLine[] => {
        let crmLostRiskLines: CrmLostRiskLine[] = [];
        let crmLostRiskLine: CrmLostRiskLine = new CrmLostRiskLine();
    
        crmLostRiskLine.lossCauseCode = riskCode;
        crmLostRiskLine.sourceTable = 'Lead';
        crmLostRiskLine.sourceId = props.leadRef.id;

        crmLostRiskLines.push(crmLostRiskLine);
        crmLostRiskLines.push(crmLostRiskLine);
        crmLostRiskLines.push(crmLostRiskLine);

        return crmLostRiskLines;
    }

    const initLeadIndustries = (): LeadIndustry[] => {
        let leadIndustries: LeadIndustry[] = [];
        let leadIndustry: LeadIndustry = new LeadIndustry();

        switch(props.leadConfigDetail.leadIndustries.length)
        {
            case 1:
                leadIndustries = leadIndustries.concat(props.leadConfigDetail.leadIndustries);
                leadIndustries.push(leadIndustry);
                leadIndustries.push(leadIndustry);
                break;
            case 2:
                leadIndustries = leadIndustries.concat(props.leadConfigDetail.leadIndustries);
                leadIndustries.push(leadIndustry);
                break;
            case 3:
                leadIndustries = leadIndustries.concat(props.leadConfigDetail.leadIndustries);
                break;
            default:
                leadIndustries.push(leadIndustry);
                leadIndustries.push(leadIndustry);
                leadIndustries.push(leadIndustry);
                break;
        }

        return leadIndustries;
    }

    const loadIndustryTypeOptions = () => {
        let currentOptions: IComboBoxOption[] = [];
        
        currentOptions.push({
            key: 0,
            text: t('demoCenter:TrialModal:Dialog:defaultIndustryType')!
        });
      
        props.industryTypes.forEach(element => {
            currentOptions.push({
                key: element.id,
                text: element.translatedDescription
            });
        });

        setIndustryOptions(currentOptions);
    };
    
    const renderZipCodePlaceHolder = (): string => {
        if (!configuration.lead.foreignAddress)
            return t('demoCenter:TrialModal:Dialog:Content:zipCodePlaceHolder')!
        
        return "";
    }

    const buildMigrationRiskListColumns = (): IColumn[] => {
        return (
            [
                {
                    key: 'selected',
                    name: '',
                    fieldName: 'selected',
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item: MigrationLostRiskLineDetail, index?, column?) => {
                        return (
                            <Checkbox
                                disabled={isFieldDisabled()}
                                checked={item.selected}
                                onChange={(ev, newValue) => {handleLeadMigrationPropertyChange('selected',newValue, index!)}}
                            />
                        );
                    }
                },
                {
                    key: 'description',
                    name: t('common:qualification:Text0001Lbl'),
                    fieldName: 'description',
                    minWidth: 250,
                    maxWidth: 250,
                    onRender: (item: MigrationLostRiskLineDetail, index?, column?) => {
                        return (
                            <TooltipHost
                                content={item.notes}
                                id={"tootlip-quote-"+index}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                {item.description}
                            </TooltipHost>
                        );
                    }
                },
                {
                    key: 'notes',
                    name: t('common:qualification:Text0002Lbl'),
                    fieldName: 'notes',
                    minWidth: 200,
                    maxWidth: 200,
                    onRender: (item: MigrationLostRiskLineDetail, index?, column?) => {
                        return (
                            <CommonLeadModalField 
                                disabled={isFieldDisabled()}
                                fieldName='notes'
                                fieldType={SystemFieldType.String}
                                mandatory={false}
                                placeholder={t('common:qualification:Text0062Lbl')!}
                                onChange={(newValue, key) => { handleLeadMigrationPropertyChange('notes',newValue, index!)}}
                                value={item.notes}
                            />
                        );
                    }
                }
            ]
        );
    };

    const buildQuotePerimeterListColumns = (): IColumn[] => {

        return (
            [
                {
                    key: 'selected',
                    name: '',
                    fieldName: 'selected',
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item: LeadQuoteDetails, index?, column?) => {
                        return (
                            <Checkbox
                                disabled={isFieldDisabled()}
                                checked={item.selected}
                                onChange={(ev, newValue) => {handleLeadQuotePropertyChange('selected',newValue, index!)}}
                            />
                        );
                    }
                },
                {
                    key: 'description',
                    name: t('common:qualification:Text0001Lbl'),
                    fieldName: 'description',
                    minWidth: 250,
                    maxWidth: 250,
                    onRender: (item: LeadQuoteDetails, index?, column?) => {
                        return (
                            <TooltipHost
                                content={item.notes}
                                id={"tootlip-quote-"+index}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                {item.description}
                            </TooltipHost>
                        );
                    }
                },
                {
                    key: 'note',
                    name: t('common:qualification:Text0002Lbl'),
                    fieldName: 'note',
                    minWidth: 200,
                    maxWidth: 200,
                    onRender: (item: LeadQuoteDetails, index?, column?) => {
                        return (
                            <CommonLeadModalField 
                                disabled={isFieldDisabled()}
                                fieldName='notes'
                                placeholder={t('common:qualification:Text0062Lbl')!}
                                fieldType={SystemFieldType.String}
                                mandatory={false}
                                onChange={(newValue, key) => { handleLeadQuotePropertyChange('notes',newValue, index!)}}
                                value={item.notes}
                            />
                        );
                    }
                }
            ]
        );
    };

    const buildTimingListColumns = (): IColumn[] => {

        return (
            [
                {
                    key: 'selected',
                    name: '',
                    fieldName: 'selected',
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item: TimingListItem, index?, column?) => {
                        return (
                            <Checkbox
                                disabled={isFieldDisabled()}
                                checked={item.selected}
                                onChange={(ev, newValue) => {
                                    handleLeadPropertyChange('liveMode', item.id);
                                    buildTimingListItems(item.id - 1);
                                }}
                            />
                        );
                    }
                },
                {
                    key: 'title',
                    name: t('common:qualification:Text0004Lbl'),
                    fieldName: 'title',
                    minWidth: 270,
                    maxWidth: 270,
                    onRender: (item: TimingListItem, index?, column?) => {
                        return (
                            <Stack
                                styles={{
                                    root: {
                                        fontWeight: item.highlited ? '700': 'none',
                                    }
                                }}
                            >
                                {item.title}
                            </Stack>
                        );
                    }
                },
                {
                    key: 'operatingMode',
                    name: t('common:qualification:Text0005Lbl'),
                    fieldName: 'operatingMode',
                    minWidth: 230,
                    maxWidth: 230,
                    onRender: (item: TimingListItem, index?, column?) => {
                        return (
                            <Stack
                                styles={{
                                    root: {
                                        fontWeight: item.highlited ? '700': 'none',
                                    }
                                }}
                            >
                                {item.operatingMode}
                            </Stack>
                        );
                    }
                },
                {
                    key: 'promo',
                    name: 'Promo',
                    fieldName: 'promo',
                    minWidth: 300,
                    maxWidth: 300,
                    onRender: (item: TimingListItem, index?, column?) => {
                        return (
                            <Stack
                                styles={{
                                    root: {
                                        fontWeight: item.highlited ? '700': 'none',
                                    }
                                }}
                            >
                                {item.promo}
                            </Stack>
                        );
                    }
                },
                {
                    key: 'suggestedTo',
                    name: t('common:qualification:Text0006Lbl'),
                    fieldName: 'suggestedTo',
                    minWidth: 300,
                    maxWidth: 300,
                    onRender: (item: TimingListItem, index?, column?) => {
                        return (
                            <Stack
                                styles={{
                                    root: {
                                        fontWeight: item.highlited ? '700': 'none',
                                    }
                                }}
                            >
                                <TooltipHost
                                    content={item.suggestedTo}
                                    id={"tootlip-liveMode-"+index}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{root:{display:'inline-block !important'}}}
                                >
                                    {item.suggestedTo}
                                </TooltipHost>
                            </Stack>
                        );
                    }
                }
            ]
        );
    };

    const buildBusinessLineListColumns = (): IColumn[] => {
        return (
            [
                {
                    key: 'typology',
                    fieldName: 'typology',
                    name: t('common:qualification:Text0007Lbl'),
                    data:t('common:qualification:Text0008Lbl'),
                    minWidth: 250,
                    maxWidth: 300,
                    onRenderHeader: (props) => {
                        return(
                            <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalFill
                                verticalAlign="center"
                            >
                                {props?.column.name}
                                <TooltipHost
                                    content={props?.column.data}
                                    id={"tootlip-"+props?.column.key}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{root:{display:'inline-block !important'}}}
                                >
                                    <IconButton id={"iconButton-"+props?.column.key} iconProps={{iconName: 'Info'}} />
                                </TooltipHost>
                            </Stack>
                        )
                    },
                    onRender: (item: LeadIndustry, index?, column?) => {
                        return (
                            <CommonLeadModalField 
                                disabled={isFieldDisabled()}
                                fieldName='typologyId'
                                fieldType={SystemFieldType.Option}
                                mandatory={false}
                                defaultValue={'0'}
                                value={item.typologyId}
                                cmbOptions={[
                                    {
                                        key: 0,
                                        text: t('common:qualification:Text0049Lbl'),
                                        
                                    },
                                    {
                                        key: 1,
                                        text: t('common:qualification:Text0045Lbl'),
                                        
                                    },
                                    {
                                        key: 2,
                                        text: t('common:qualification:Text0046Lbl')
                                    },
                                    {
                                        key: 3,
                                        text: t('common:qualification:Text0047Lbl')
                                    },
                                    {
                                        key: 4,
                                        text: t('common:qualification:Text0048Lbl')
                                    }
                                ]}
                                onChange={(newValue: any, key: string) => {
                                    handleLeadIndustryPropertyChange(key, Number(newValue), index!);
                                }}
                            />
                        );
                    }
                },
                {
                    key: 'description',
                    fieldName: 'description',
                    name: t('common:qualification:Text0009Lbl'),
                    data: t('common:qualification:Text0010Lbl'),
                    minWidth: 250,
                    maxWidth: 300,
                    onRenderHeader: (props) => {
                        return(
                            <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalFill
                                verticalAlign="center"
                            >
                                {props?.column.name}
                                <TooltipHost
                                    content={props?.column.data}
                                    id={"tootlip-"+props?.column.key}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{root:{display:'inline-block !important'}}}
                                >
                                    <IconButton id={"iconButton-"+props?.column.key} iconProps={{iconName: 'Info'}} />
                                </TooltipHost>
                            </Stack>
                        )
                    },
                    onRender: (item: LeadIndustry, index?, column?) => {
                        return (
                            <CommonLeadModalField 
                                disabled={isFieldDisabled()}
                                fieldName='description'
                                placeholder={t('common:qualification:Text0010Lbl')!}
                                fieldType={SystemFieldType.String}
                                mandatory={false}
                                onChange={(newValue, key) => { handleLeadIndustryPropertyChange('description',newValue, index!)}}
                                value={item.description}
                            />
                        );
                    }
                },
                {
                    key: 'salesChannel',
                    fieldName: 'salesChannel',
                    name: t('common:qualification:Text0011Lbl'),
                    data: t('common:qualification:Text0012Lbl'),
                    minWidth: 250,
                    maxWidth: 300,
                    onRenderHeader: (props) => {
                        return(
                            <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalFill
                                verticalAlign="center"
                            >
                                {props?.column.name}
                                <TooltipHost
                                    content={props?.column.data}
                                    id={"tootlip-"+props?.column.key}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{root:{display:'inline-block !important'}}}
                                >
                                    <IconButton id={"iconButton-"+props?.column.key} iconProps={{iconName: 'Info'}} />
                                </TooltipHost>
                            </Stack>
                        )
                    },
                    onRender: (item: LeadIndustry, index?, column?) => {
                        return (
                            <CommonLeadModalField
                                disabled={isFieldDisabled()}
                                fieldName='salesChannel'
                                placeholder={t('common:qualification:Text0012Lbl')!}
                                fieldType={SystemFieldType.String}
                                mandatory={false}
                                onChange={(newValue, key) => { handleLeadIndustryPropertyChange('salesChannel', newValue, index!)}}
                                value={item.salesChannel}
                            />
                        );
                    }
                },
                {
                    key: 'revenue',
                    fieldName: 'revenue',
                    name: t('common:qualification:Text0013Lbl'),
                    minWidth: 250,
                    maxWidth: 300,
                    onRender: (item: LeadIndustry, index?, column?) => {
                        return (
                            <CommonLeadModalField 
                                disabled={isFieldDisabled()}
                                fieldName='revenue'
                                fieldType={SystemFieldType.Number}
                                mandatory={false}
                                onChange={(newValue, key) => { handleLeadIndustryPropertyChange('revenue', Number(newValue), index!)}}
                                value={item.revenue}
                            />
                        );
                    }
                }
            ]
        );
    };

    const buildTimingListItems = (selectedIndex: number) => {

        let currenttimingListItems: TimingListItem[] = [
            {
                id: 1,
                title: t('common:qualification:Text0014Lbl'),
                operatingMode: t('common:qualification:Text0015Lbl'),
                promo: t('common:qualification:Text0016Lbl'),
                suggestedTo: t('common:qualification:Text0017Lbl'),
                selected: false
            },
            {
                id: 2,
                title: t('common:qualification:Text0018Lbl'),
                operatingMode: t('common:qualification:Text0019Lbl'),
                promo: t('common:qualification:Text0020Lbl'),
                suggestedTo: t('common:qualification:Text0021Lbl'),
                selected: false,
                highlited: true
            },
            {
                id: 3,
                title: t('common:qualification:Text0022Lbl'),
                operatingMode: t('common:qualification:Text0023Lbl'),
                promo: t('common:qualification:Text0024Lbl'),
                suggestedTo: t('common:qualification:Text0025Lbl'),
                selected: false
            },
            {
                id: 4,
                title: t('common:qualification:Text0026Lbl'),
                operatingMode: t('common:qualification:Text0027Lbl'),
                promo: '',
                suggestedTo: t('common:qualification:Text0028Lbl'),
                selected: false
            }
        ];
        
        if(selectedIndex >= 0)
            currenttimingListItems[selectedIndex].selected = true;

        setTimingListItems(currenttimingListItems);
    }
    
    const buildMigrationLostRiskLine = (configuration: SaveLeadConfigChecklist) => {

        let migrationListItem: MigrationLostRiskLineDetail[] = [
            {
                id: 10000,
                description: t('common:qualification:Text0029Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 20000,
                description: t('common:qualification:Text0030Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 30000,
                description: t('common:qualification:Text0031Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 40000,
                description: t('common:qualification:Text0032Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 50000,
                description: t('common:qualification:Text0033Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 60000,
                description: t('common:qualification:Text0034Lbl'),
                notes: '',
                selected: false
            },
            {
                id: 70000,
                description: t('common:qualification:Text0035Lbl'),
                notes: '',
                selected: false
            }
        ]

        migrationListItem.forEach((item: MigrationLostRiskLineDetail) => {
            let index: number = props.leadConfigDetail.migrationRiskLines.findIndex(e => e.id == item.id);
            if(index >= 0){
                let currentItem: MigrationLostRiskLineDetail = props.leadConfigDetail.migrationRiskLines[index];

                item.selected = true;
                item.notes = currentItem.notes
            }
        })

        configuration.details.migrationRiskLines = migrationListItem;
    }
    
    const displayErrorMessage = (error: string) => {
        setErrorMessage(<MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>);
    };

    const initializeData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            loadIndustryTypeOptions();
            initCountryRegionData().then((resp) => {
                //Handle configuration for Business Needs Lost Risk Lines
                let configuration: SaveLeadConfigChecklist = new SaveLeadConfigChecklist();
                if(props.leadConfigDetail.businessNeedsRiskLines.length === 0)
                    configuration.details.businessNeedsRiskLines = initCrmLostRiskLines('BUSINESS_NEEDS');
                else
                    configuration.details.businessNeedsRiskLines = props.leadConfigDetail.businessNeedsRiskLines;
    
                //Handle configuration for Business Needs Lost Risk Lines
                if(props.leadConfigDetail.businessNeedsNotesRiskLines.length === 0)
                    configuration.details.businessNeedsNotesRiskLines = initCrmLostRiskLines('BUSINESS_NEEDS_NOTE');
                else
                    configuration.details.businessNeedsNotesRiskLines = props.leadConfigDetail.businessNeedsNotesRiskLines;
    
                //Handle configuration for Business Needs Lost Risk Lines
                if(props.leadConfigDetail.gapRiskLines.length === 0)
                    configuration.details.gapRiskLines = initCrmLostRiskLines('GAP');
                else
                    configuration.details.gapRiskLines = props.leadConfigDetail.gapRiskLines;
    
                //Handle configuration for Migrations Lost Risk Lines
                buildMigrationLostRiskLine(configuration);
                //Handle configuration for Quote Perimeter
                configuration.details.leadQuoteDetails = props.leadConfigDetail.leadQuoteDetails;
    
                //Handle configuration for Lead Industries
                configuration.details.leadIndustries = initLeadIndustries();
    
                //Build timing list live mode
                buildTimingListItems(props.leadRef.liveMode - 1);
    
                if (!props.leadRef.setupCompanyCompleted && props.leadRef.companyName === props.leadRef.lastname)
                    props.leadRef.lastname = "";
                
                configuration.lead = props.leadRef;

                if (!configuration.lead.country)
                    configuration.lead.country = suggestCountry(configuration);

                configuration.lead.evaluationMode = props.leadRef.evaluationMode;
                configuration.leadToLiveChecklist = props.leadToLiveChecklist;
                setConfiguration(configuration);

                setDataInitialized(true);

                resolve(true);
            })
            .catch((err) => {
                reject(err);
            });

        });
    }

    const isFieldDisabled = (): boolean => {
        return(props.savingData || validatingVat);
    }

    const generateCompanyAddress = (companyData: OpenAPICompanyITAdvancedData): string => {
        let address = companyData?.address.registeredOffice;
        if (address?.toponym && address.street && address.streetNumber)
            return address.toponym + ' ' + address.street + ', ' + address.streetNumber;

        return address?.streetName ?? '';
    }

    const getBalanceSheet = (companyData: OpenAPICompanyITAdvancedData): BalanceSheet => {
        if (companyData.balanceSheets?.last?.turnover > 0) {
            return companyData.balanceSheets.last;
        }
    
        const balanceSheets: BalanceSheet[] = companyData.balanceSheets?.all || [];
        for (const balanceSheet of balanceSheets) {
            if (balanceSheet.turnover > 0) {
                return balanceSheet;
            }
        }
        
        return balanceSheets[0] || {};
    }

    const convertStringToDate = (dateString: string): Date | null => {
        const parsedDate = new Date(dateString);
        return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }

    const checkCompanyData = (vat: string) => {
        if (!vat || vat.length !== 11)
            return;
        
        if (vat === configuration.lead.validatedVat)
            return;
        
        let currConfiguration: SaveLeadConfigChecklist = configuration;
        let currentLead = configuration.lead;
        currentLead.validatedVat = vat;
        currConfiguration.lead = currentLead;
        setConfiguration(currConfiguration);

        setValidatingVat(true);

        const adminService = new AdminService();
        var promise = adminService.getVatOpenAPIData(vat, configuration.lead.id, 0)
        .then((resp)=>{
            if (resp.success) {
                let companyData: OpenAPICompanyITAdvancedData = resp.data[0];
                let currConfiguration: SaveLeadConfigChecklist = configuration;
                let currentLead = configuration.lead;
                let cityOptions: IComboBoxOption[] = [];
                let countyOptions: IComboBoxOption[] = [];

                if (companyData?.companyName)
                    currentLead.companyName = companyData.companyName;
                if (companyData?.sdiCode)
                    currentLead.sdi = companyData.sdiCode;
                if (companyData?.pec)
                    currentLead.pecEmail = companyData.pec;
                if (generateCompanyAddress(companyData))
                    currentLead.address = generateCompanyAddress(companyData);
                if (companyData?.address.registeredOffice.town)
                    currentLead.city = companyData.address.registeredOffice.town;
                if (companyData?.address.registeredOffice.province)
                    currentLead.county = companyData.address.registeredOffice.province;
                if (companyData?.address.registeredOffice.zipCode)
                    currentLead.zipCode = companyData.address.registeredOffice.zipCode;

                const balanceSheet = getBalanceSheet(companyData);

                if (companyData?.atecoClassification?.ateco?.code)
                    currentLead.atecoCode = companyData.atecoClassification.ateco.code;
                if (companyData?.atecoClassification?.ateco?.description)
                    currentLead.atecoDescription = companyData.atecoClassification.ateco.description;

                if (balanceSheet.turnover)
                    currentLead.turnover = balanceSheet.turnover;
                if (balanceSheet.employees)
                    currentLead.noOfEmployees = balanceSheet.employees;
                if (balanceSheet.year)
                    currentLead.balanceYear = balanceSheet.year;
                if (balanceSheet.netWorth)
                    currentLead.netWorth = balanceSheet.netWorth;
                if (balanceSheet.shareCapital)
                    currentLead.shareCapital = balanceSheet.shareCapital;
                if (balanceSheet.totalStaffCost)
                    currentLead.totalStaffCost = balanceSheet.totalStaffCost;
                if (balanceSheet.totalAssets)
                    currentLead.totalAssets = balanceSheet.totalAssets;
                if (balanceSheet.avgGrossSalary)
                    currentLead.avgGrossSalary = balanceSheet.avgGrossSalary;

                let companyStartDate = convertStringToDate(companyData.startDate);
                let companyRegistrationDate = convertStringToDate(companyData.registrationDate);
                let companyEndDate = convertStringToDate(companyData.endDate);
                if (companyStartDate)
                    currentLead.companyStartDate = companyStartDate;
                if (companyRegistrationDate)
                    currentLead.companyRegistrationDate = companyRegistrationDate;
                if (companyEndDate)
                    currentLead.companyEndDate = companyEndDate;            
                
                if(currentLead.zipCode !== ""){
                    let postalCodeOptions: PostalCode[] = postalCodeData.filter(item => item.zipCode == currentLead.zipCode);
                    postalCodeOptions.forEach((value: PostalCode, index: number, array: PostalCode[]) => {
                        if(countyOptions.findIndex(item => item.key == value.county) < 0){
                            countyOptions.push({
                                key: value.county,
                                text: value.county
                            });
                        }
            
                        cityOptions.push({
                            key: value.city,
                            text: value.city
                        });
                    })

                    if (countryOptions.length > 0) {
                        if (countryOptions.find(item => item.key.toString().toUpperCase() === 'IT'))
                            currentLead.country = countryOptions.find(item => item.key.toString() === 'IT')!.key.toString();
                    }
            
                    if(cityOptions.length === 1) {
                        currentLead.city = cityOptions[0].key.toString();
                    }
                    else {
                        if (cityOptions.findIndex(item => item.key.toString().toUpperCase() === currentLead.city.toUpperCase()) >= 0)
                            currentLead.city = cityOptions[cityOptions.findIndex(item => item.key.toString().toUpperCase() === currentLead.city.toUpperCase())].key.toString();
                    }

                    if(countyOptions.length === 1){
                        currentLead.county = countyOptions[0].key.toString();
                    }
                    else {
                        if (countyOptions.findIndex(item => item.key.toString().toUpperCase() === currentLead.county.toUpperCase()))
                            currentLead.county = countyOptions[cityOptions.findIndex(item => item.key.toString() === currentLead.county.toUpperCase())].key.toString();
                    }
                }

                currConfiguration.lead = currentLead;
                setConfiguration(currConfiguration);
                props.checkLeadData(currConfiguration);

                setCityOptions(cityOptions);
                setCountyOptions(countyOptions);
            }
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(() => {
            setValidatingVat(false);
        });

        ToastService.showPromiseMessage(promise, t('common:qualification:Text0067Lbl')!, "", "");
    }

    useEffect(() => {
        if(props.showModal)
        {
            setDialogDataStatus(SystemDataLoadingStatus.Loading);
            ToastService.showMessage(MessageType.Pending, "Caricamento in corso...", "LOADING");
            initializeData().then(() => {
                ToastService.close("LOADING");
                setDialogDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((err) => {
                ToastService.update("LOADING", { isLoading: false });
                ToastService.showMessage(MessageType.Error, err);
                setDialogDataStatus(SystemDataLoadingStatus.Error);
            })
        }
    }, [props.showModal])

    useEffect(() =>{
        if (dataInitialized)
            checkCompanyData(configuration.lead.vat);
    }, [postalCodeData]);

    useEffect(() => {
        if(props.validationErrors.length == 0)
            setErrorMessage(<></>);
    }, [props.validationErrors])

    if(!SystemCore.isFinishedDataStatus(dialogDataStatus)){
        return (<></>);
    }
    else{
        return(
            <Dialog
                hidden={!props.showModal}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={"95vw"}
                maxWidth={"95vw"}
            >
                <CommonLeadModalContent>
                    <CommonLeadModalGroup id="generalModalLead" title={t('common:qualification:Text0036Lbl')} subtitle={t('common:qualification:Text0054Lbl')!}>
                        {
                            (props.leadRef.legalEntityCode === 'EOL')
                            ? <>
                                <CommonLeadModalRow>
                                    <CommonLeadModalField
                                        fieldName="vat"
                                        maxLength={11}
                                        mandatory={(props.leadRef.legalEntityCode === 'EOL')}
                                        disabled={isFieldDisabled()}
                                        fieldType={SystemFieldType.String}
                                        label={t('leadDashboard:leadModal:field:vat')!}
                                        errorMessage={getFieldErrorMessage("vat")}
                                        onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                        value={configuration.lead.vat}
                                        placeholder={t('common:qualification:Text0066Lbl')!}
                                        onValidate={(newValue)=>{checkCompanyData(newValue)}}
                                    />
                                </CommonLeadModalRow>
                                <Separator />
                            </>
                            : <></>
                        }
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="companyName"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:companyName')!}
                                errorMessage={getFieldErrorMessage("companyName")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.companyName}
                                placeholder=''
                            />
                            <CommonLeadModalField
                                fieldName="email"
                                mandatory={true}
                                disabled={true} 
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:mail')!}
                                errorMessage={getFieldErrorMessage("email")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.email}
                                placeholder=''
                            />
                        </CommonLeadModalRow>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="firstname"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:firstname')!}
                                errorMessage={getFieldErrorMessage("firstname")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.firstname}
                                placeholder=''
                            />
                            <CommonLeadModalField
                                fieldName="lastname"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:lastname')!}
                                errorMessage={getFieldErrorMessage("lastname")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.lastname}
                                placeholder=''
                            />
                        </CommonLeadModalRow>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="country"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.Option}
                                cmbOptions={countryOptions}
                                label={t('leadDashboard:leadModal:field:country')!}
                                errorMessage={getFieldErrorMessage("country")}
                                onChange={(newValue, key) =>{
                                    getPostalCodes(newValue, true);
                                    handleLeadPropertyChange(key, newValue)
                                }}
                                value={handleCountryValue()}
                                defaultValue={configuration.lead.country}
                                placeholder=''
                            />
                            <CommonLeadModalField
                                fieldName="address"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:address')!}
                                errorMessage={getFieldErrorMessage("address")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.address}
                                placeholder=''
                            />
                        </CommonLeadModalRow>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="zipCode"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:zipCode')!}
                                errorMessage={getFieldErrorMessage("zipCode")}
                                onChange={(newValue, key) =>{
                                    validateLocationData(newValue);
                                }}
                                value={configuration.lead.zipCode}
                                placeholder={renderZipCodePlaceHolder()}
                            />
                            {
                                (postalCodeData.length > 1) ?
                                    <CommonLeadModalField
                                        fieldName="city"
                                        mandatory={true}
                                        disabled={isFieldDisabled() || (configuration.lead.zipCode == "" || configuration.lead.zipCode == null)}
                                        fieldType={SystemFieldType.Option}
                                        cmbOptions={cityOptions}
                                        label={t('leadDashboard:leadModal:field:city')!}
                                        errorMessage={getFieldErrorMessage("city")}
                                        onChange={(newValue, key) =>{
                                            handleLeadPropertyChange(key, newValue);
                                        }}
                                        value={configuration.lead.city}
                                        placeholder=''
                                    />
                                :
                                    <CommonLeadModalField
                                        fieldName="city"
                                        mandatory={true}
                                        disabled={isFieldDisabled() || (configuration.lead.zipCode == "" || configuration.lead.zipCode == null)}
                                        fieldType={SystemFieldType.String}
                                        label={t('leadDashboard:leadModal:field:city')!}
                                        errorMessage={getFieldErrorMessage("city")}
                                        onChange={(newValue, key) =>{
                                            handleLeadPropertyChange(key, newValue)
                                        }}
                                        value={configuration.lead.city}
                                        placeholder=''
                                    />
                            }
                        </CommonLeadModalRow>
                        <CommonLeadModalRow>
                            {
                                (postalCodeData.length > 1) ?
                                    <CommonLeadModalField
                                        fieldName="county"
                                        mandatory={true}
                                        disabled={isFieldDisabled() || (configuration.lead.zipCode == "" || configuration.lead.zipCode == null)}
                                        fieldType={SystemFieldType.Option}
                                        cmbOptions={countyOptions}
                                        label={t('leadDashboard:leadModal:field:county')!}
                                        errorMessage={getFieldErrorMessage("county")}
                                        onChange={(newValue, key) =>{
                                            handleLeadPropertyChange(key, newValue)
                                        }}
                                        value={configuration.lead.county}
                                        placeholder=''
                                    />
                                :
                                    <CommonLeadModalField
                                        fieldName="county"
                                        mandatory={true}
                                        disabled={isFieldDisabled() || (configuration.lead.zipCode == "" || configuration.lead.zipCode == null)}
                                        fieldType={SystemFieldType.String}
                                        label={t('leadDashboard:leadModal:field:county')!}
                                        errorMessage={getFieldErrorMessage("county")}
                                        onChange={(newValue, key) =>{
                                            handleLeadPropertyChange(key, newValue)
                                        }}
                                        value={configuration.lead.county}
                                        placeholder=''
                                    />
                            }
                        </CommonLeadModalRow>
                        {(props.leadRef.legalEntityCode === 'EOL')
                        ?
                            <>
                            <Separator />
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="atecoCode"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.String}
                                    label={t('leadDashboard:leadModal:field:atecoCode')!}
                                    value={configuration.lead.atecoCode}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="atecoDescription"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.String}
                                    label={t('leadDashboard:leadModal:field:atecoDescription')!}
                                    value={configuration.lead.atecoDescription}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="balanceYear"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:balanceYear')!}
                                    value={configuration.lead.balanceYear}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="companyStartDate"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Date}
                                    label={t('leadDashboard:leadModal:field:companyStartDate')!}
                                    value={configuration.lead.companyStartDate}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="companyRegistrationDate"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Date}
                                    label={t('leadDashboard:leadModal:field:companyRegistrationDate')!}
                                    value={configuration.lead.companyRegistrationDate}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="companyEndDate"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Date}
                                    label={t('leadDashboard:leadModal:field:companyEndDate')!}
                                    value={configuration.lead.companyEndDate}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="turnover"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:turnover')!}
                                    value={configuration.lead.turnover}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="noOfEmployees"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:noOfEmployees')!}
                                    value={configuration.lead.noOfEmployees}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="netWorth"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:netWorth')!}
                                    value={configuration.lead.netWorth}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="shareCapital"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:shareCapital')!}
                                    value={configuration.lead.shareCapital}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="totalStaffCost"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:totalStaffCost')!}
                                    value={configuration.lead.totalStaffCost}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                                <CommonLeadModalField
                                    fieldName="totalAssets"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:totalAssets')!}
                                    value={configuration.lead.totalAssets}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            <CommonLeadModalRow>
                                <CommonLeadModalField
                                    fieldName="avgGrossSalary"
                                    mandatory={false}
                                    disabled
                                    fieldType={SystemFieldType.Number}
                                    label={t('leadDashboard:leadModal:field:avgGrossSalary')!}
                                    value={configuration.lead.avgGrossSalary}
                                    onChange={(newValue, key) =>{
                                        handleLeadPropertyChange(key, newValue)
                                    }}
                                />
                            </CommonLeadModalRow>
                            </>
                        : <></>
                        }
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="businessLineModalLead" title={t('common:qualification:Text0037Lbl')} subtitle={t('common:qualification:Text0055Lbl')!}>
                        <CommonLeadModalSelectionList
                            id='businessLineListModalLead'
                            items={configuration.details.leadIndustries}
                            columns={buildBusinessLineListColumns()}
                        />
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="quoteModalLead" title={t('common:qualification:Text0038Lbl')} subtitle={t('common:qualification:Text0056Lbl')!}>
                        <CommonLeadModalSelectionList 
                            items={configuration.details.leadQuoteDetails}
                            columns={buildQuotePerimeterListColumns()}
                        />
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="businessNeedsModalLead" title={t('leadDashboard:leadModal:field:businessNeeds:title')} subtitle={t('common:qualification:Text0057Lbl')!}>
                        <CommonLeadModalRow>
                            <Stack
                                verticalFill
                                verticalAlign="start"
                                styles={{
                                    root: {
                                        width: '100%'
                                    }
                                }}
                                id='businessNeedsKey'
                            >
                                <TextField
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00001Lbl')!}
                                    value={configuration.details.businessNeedsRiskLines[0] !== undefined ? configuration.details.businessNeedsRiskLines[0].description : ""}
                                    disabled={businessNeedDisabled}
                                    maxLength={300}
                                    required={true}
                                    styles={{
                                        root: {
                                            marginBottom: 15
                                        }
                                    }}
                                    errorMessage={getFieldErrorMessage('LostRisk1')}
                                    onChange={(ev,newValue) => {handleBusinessNeedsLostRiskPropertyChange('description', newValue, 0)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00002Lbl')!}
                                    value={configuration.details.businessNeedsRiskLines[1] !== undefined ? configuration.details.businessNeedsRiskLines[1].description : ""}
                                    disabled={true}
                                    maxLength={300}
                                    styles={{
                                        root: {
                                            marginBottom: 5
                                        }
                                    }}
                                    onChange={(ev,newValue) => {handleBusinessNeedsLostRiskPropertyChange('description', newValue, 1)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00003Lbl')!}
                                    value={configuration.details.businessNeedsRiskLines[2] !== undefined ? configuration.details.businessNeedsRiskLines[2].description : ""}
                                    disabled={true}
                                    maxLength={300}
                                    onChange={(ev,newValue) => {handleBusinessNeedsLostRiskPropertyChange('description', newValue, 2)}}
                                />
                                <TextField
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00004Lbl')!}
                                    value={configuration.details.businessNeedsNotesRiskLines[0] !== undefined ? configuration.details.businessNeedsNotesRiskLines[0].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    styles={{
                                        root: {
                                            marginBottom: 5,
                                            marginTop: 20
                                        }
                                    }}
                                    onChange={(ev,newValue) => {handleBusinessNeedsNotesLostRiskPropertyChange('description', newValue, 0)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00005Lbl')!}
                                    value={configuration.details.businessNeedsNotesRiskLines[1] !== undefined ? configuration.details.businessNeedsNotesRiskLines[1].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    styles={{
                                        root: {
                                            marginBottom: 5
                                        }
                                    }}
                                    onChange={(ev,newValue) => {handleBusinessNeedsNotesLostRiskPropertyChange('description', newValue, 1)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00006Lbl')!}
                                    value={configuration.details.businessNeedsNotesRiskLines[2] !== undefined ? configuration.details.businessNeedsNotesRiskLines[2].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    onChange={(ev,newValue) => {handleBusinessNeedsNotesLostRiskPropertyChange('description', newValue, 2)}}
                                />
                                <TextField
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00007Lbl')!}
                                    value={configuration.details.gapRiskLines[0] !== undefined ? configuration.details.gapRiskLines[0].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    styles={{
                                        root: {
                                            marginBottom: 5,
                                            marginTop: 20
                                        }
                                    }}
                                    onChange={(ev,newValue) => {handleGapLostRiskPropertyChange('description', newValue, 0)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00008Lbl')!}
                                    value={configuration.details.gapRiskLines[1] !== undefined ? configuration.details.gapRiskLines[1].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    styles={{
                                        root: {
                                            marginBottom: 5
                                        }
                                    }}
                                    onChange={(ev,newValue) => {handleGapLostRiskPropertyChange('description', newValue, 1)}}
                                />
                                <TextField 
                                    label={t('leadDashboard:leadModal:field:businessNeeds:text00009Lbl')!}
                                    value={configuration.details.gapRiskLines[2] !== undefined ? configuration.details.gapRiskLines[2].description : ""}
                                    disabled={isFieldDisabled()}
                                    maxLength={300}
                                    onChange={(ev,newValue) => {handleGapLostRiskPropertyChange('description', newValue, 2)}}
                                />
                            </Stack>
                        </CommonLeadModalRow>
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="migrationModalLead" title={t('common:qualification:Text0039Lbl')} subtitle={t('common:qualification:Text0058Lbl')!}>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="currentERPName"
                                mandatory={false}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.String}
                                label={t('leadDashboard:leadModal:field:currentSystem')!}
                                errorMessage={getFieldErrorMessage("currentErpName")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                value={configuration.lead.currentERPName}
                                placeholder={t('common:qualification:Text0061Lbl')!}
                            />
                        </CommonLeadModalRow>
                        <CommonLeadModalSelectionList 
                            items={configuration.details.migrationRiskLines}
                            columns={buildMigrationRiskListColumns()}
                        />
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="timingModalLead" title={t('common:qualification:Text0040Lbl')} subtitle={t('common:qualification:Text0059Lbl')!}>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="decisionDate"
                                mandatory={false}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.Date}
                                label={t('leadDashboard:leadModal:field:decisionDate')!}
                                value={configuration.lead.decisionDate}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                placeholder={t('leadDashboard:leadModal:field:decisionDatePlaceHolder')!}
                            />
                            <CommonLeadModalField
                                fieldName="expectedLiveDate"
                                mandatory={false}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.Date}
                                label={t('leadDashboard:leadModal:field:expectedLiveDate')!}
                                value={configuration.lead.expectedLiveDate}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, newValue)}}
                                placeholder={t('leadDashboard:leadModal:field:expectedLiveDatePlaceHolder')!}
                            />
                        </CommonLeadModalRow>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="rating"
                                disabled={isFieldDisabled()}
                                mandatory={false}
                                fieldType={SystemFieldType.Option}
                                cmbOptions={[
                                    {key: 0, text: t('adminCenter:leadCard:rating0')!},
                                    {key: 1, text: t('adminCenter:leadCard:rating1')!},
                                    {key: 2, text: t('adminCenter:leadCard:rating2')!}]}
                                defaultValue={configuration.lead.rating.toString()}
                                label={t('leadDashboard:leadModal:field:rating')!}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, Number(newValue))}}
                                value={configuration.lead.rating}
                            />
                        </CommonLeadModalRow>
                        <Stack
                            styles={{
                                root: {
                                    marginTop: 15
                                }
                            }}
                        >
                            <CommonLeadModalSelectionList 
                                items={timingListIntems}
                                columns={buildTimingListColumns()}
                            />
                        </Stack>
                    </CommonLeadModalGroup>
                    <CommonLeadModalGroup id="budgetModalLead" title={t('common:qualification:Text0041Lbl')} subtitle={t('common:qualification:Text0060Lbl')!}>
                        <CommonLeadModalRow>
                            <CommonLeadModalField
                                fieldName="evaluationMode"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.Radio}
                                radioOptions={[
                                    { 
                                        key: "1", 
                                        text: t('leadDashboard:leadModal:field:evaluationMode:text00001Lbl')!, 
                                        styles:{
                                            root: {
                                                paddingRight: 10
                                            }
                                        },
                                        onRenderField: (props, render) => {
                                        return (
                                            <TooltipHost
                                                content={t('leadDashboard:leadModal:field:evaluationMode:tooltipText1')!}
                                                id={"tootlip-selfservice"}
                                                calloutProps={{ gapSpace: 0 }}
                                                styles={{root:{display:'inline-block !important'}}}
                                            >
                                            {render!(props)}
                                            </TooltipHost>
                                        );
                                        },
                                    },
                                    { 
                                        key: '2', 
                                        text: t('leadDashboard:leadModal:field:evaluationMode:text00002Lbl')!, 
                                        styles:{
                                            root: {
                                                paddingRight: 10
                                            }
                                        },
                                        onRenderField: (props, render) => {
                                        return (
                                            <TooltipHost
                                                content={t('leadDashboard:leadModal:field:evaluationMode:tooltipText2')!}
                                                id={"tootlip-selfservice"}
                                                calloutProps={{ gapSpace: 0 }}
                                                styles={{root:{display:'inline-block !important'}}}
                                            >
                                            {render!(props)}
                                            </TooltipHost>
                                        );
                                        },
                                    },
                                ]}
                                label={t('leadDashboard:leadModal:field:evaluationMode:label')!}
                                errorMessage={getFieldErrorMessage("evaluationMode")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, Number(newValue))}}
                                value={configuration.lead.evaluationMode}
                                placeholder=''
                            />
                            <CommonLeadModalField
                                fieldName="full_Users_No"
                                mandatory={true}
                                disabled={isFieldDisabled()}
                                fieldType={SystemFieldType.Number}
                                label={t('leadDashboard:leadModal:field:fullUsersNo')!}
                                errorMessage={getFieldErrorMessage("fullUsersNo")}
                                onChange={(newValue, key) =>{handleLeadPropertyChange(key, Number(newValue))}}
                                value={configuration.lead.full_Users_No}
                                placeholder=''
                                minValue={0}
                                maxValue={500}
                            />
                        </CommonLeadModalRow>
                    </CommonLeadModalGroup>
                </CommonLeadModalContent>
                <Stack key="errorMsg" style={{
                    marginTop: 20
                }}>
                    {errorMessage}
                </Stack>
                <DialogFooter>
                    <PrimaryButton 
                        onClick={() => {
                            if(!props.onSave(configuration))
                                displayErrorMessage(t('common:qualification:Text0042Lbl'));
                        }} 
                        text={t('leadDashboard:leadModal:saveButtonText')!} 
                        disabled={isFieldDisabled()} 
                    />
                    <DefaultButton onClick={() => {props.onCancel()}} text={t('leadDashboard:leadModal:defaultButtonText')!}  disabled={isFieldDisabled()} />
                </DialogFooter>
            </Dialog>
        );
    }
}
