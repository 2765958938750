import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCreateUserCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/user" title={'Creazione nuovo utente'} ref={ref}>
            <CardGroup name="general"  label="Generale" isOpen>
                <CardField name="firstName" label="Nome" type={SystemFieldType.String} />
                <CardField name="lastName" label="Cognome" type={SystemFieldType.String} />
                <CardField name="displayName" label="Nome visualizzato" type={SystemFieldType.String} />
                <CardField name="privateEmail" label="Email Privata" type={SystemFieldType.String} />
                <CardField name="userPrincipleName" label="Utenza AAD" type={SystemFieldType.String} />
                <CardField name="userType" type={SystemFieldType.Option} label="Tipo utente" cmbOptions={[
                    {key: 0, text: "AAD"},
                    {key: 1, text: "Private"},
                    {key: 2, text: "Partner"},
                    {key: 3, text: "Internal"}
                ]} />
                <CardField name="phone" type={SystemFieldType.String} label="Telefono" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
            </CardGroup>
            <CardGroup name="informations" label="Informazioni correlate" isOpen>
                <CardField name="mainUser" label="Amministratore So Smart" type={SystemFieldType.Boolean} />
                <CardField name="customerId" label="Id cliente" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/customer" tableRelationKey="id" tableRelationField="companyName" tableRelationFilter="?$filter=statusCode in ('10','20','30','40','50','60','70','80')" />
                <CardField name="partnerId" label="Id partner" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationField="name" tableRelationKey="id" />
            </CardGroup>
            <CardGroup name="crm"  label="CRM" isOpen={false}>
                <CardField name="crmSystemUserId" label="Id utente CRM" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/system/user" tableRelationKey="crmReferenceId" tableRelationField="fullName"  />
                <CardField name="crmPartnerContactId" label="Id contatto partner CRM" type={SystemFieldType.String} />
                <CardField name="allowAdvertisingEmail" label="Permetti email ADV" type={SystemFieldType.Boolean} />
                <CardField name="privacyPolicyAccepted" label="Privacy policy accettata" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="booking" label="Booking" isOpen={false}>
                <CardField name="bookingStaffMemberId" label="Id staff member Booking" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/booking/staff/member" tableRelationKey="id" tableRelationField="displayName" />
            </CardGroup>
        </PageContainer>    
    );
});