import React, { useRef } from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSharepointDirectoryCard } from "../AdminSharepointDirectoryCard/AdminSharepointDirectoryCard";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { PartnerService } from "../../../../ApplicationCode/Partner/PartnerService";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ToastService } from "../../../../Core/Toast/ToastService";

export const AdminSharepointDirectoryList : React.FC = () => {

    const ref = useRef<PageContainerBase>(null);
    const cardRef = useRef<PageContainerBase>(null);
    
    const partnerService = new PartnerService();
    
    return (
        <>
            <PageContainer endpoint="/odata/sharepoint/directory" pageType={SystemPageType.List} title="Directory Sharepoint" ref={ref} cardRef={cardRef} backAllowed showAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry label="Download" iconName="Download" name="DownloadFolders" isPromoted onClick={() => {
                            ToastService.showPromiseMessage(partnerService.downloadSharepointFolders(), "Download in corso...", "Download terminato con successo", "Download in errore", () => {ref.current?.reloadData()});
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Abilitata" fieldName="enabled" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Tipo entità" fieldName="entityType" minWidth={80} maxWidth={80} onRender={(item: any, index, column) => {
                        if (item.entityType === 0)
                            return "Partner"
                        else if (item.entityType === 1)
                            return "Cliente"
                    }} />
                    <ListHeaderEntry name="Id sito" fieldName="siteId" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Sito" fieldName="siteDisplayName" minWidth={130} maxWidth={130} />
                    <ListHeaderEntry name="Id lista" fieldName="listId" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Lista" fieldName="listName" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Id directory" fieldName="directoryId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="URL" fieldName="folderUrl" minWidth={500} maxWidth={500} />
                    <ListHeaderEntry name="Id referenza directory" fieldName="directoryRefId" minWidth={230} maxWidth={230} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSharepointDirectoryCard ref={cardRef} /> 
        </>
    );
}