import { CommandBar, IColumn, ICommandBarItemProps, MarqueeSelection, Text, Selection, SelectionMode, Separator, ShimmeredDetailsList, Stack, mergeStyles, SearchBox } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { UserInformationSetup, UserLicenseEntry } from "../../../Model/CustomerModels";
import { OperationType, SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { ConfirmDialog } from "../../Common/Template/Dialog/ConfirmDialog";
import { CustomerLicenseCard } from "../CustomerLicenseCard/CustomerLicenseCard";
import { CustomerUserCard } from "../CustomerUserCard/CustomerUserCard";
import "./CustomerUserList.css";
import { t } from "i18next";
import { CustomerResetPasswordCard } from "../CustomerResetPasswordCard/CustomerResetPasswordCard";
import { useNavigate } from "react-router-dom";

interface ICustomerUserListActionsProps {
    items: ICommandBarItemProps[];
} 

const CustomerUserListActions : React.FC<ICustomerUserListActionsProps> = (props) => {
    return (
        <CommandBar className="page-commandbar" items={props.items}/>
    )
}

export interface ICustomerUserListProps {
    standalone?: boolean;
}

const titleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);


const pageContainerClassName = mergeStyles([{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
}]);

const mainContainerClassName = mergeStyles([{
    width: '98%',
    backgroundColor: 'white',
    border: '1px solid #d9d9d9',
    boxShadow: '6px 7px 5px 0px rgba(230,230,230,1)',
    padding: 10,
    minHeight: 'calc(100vh - 100px)'
}]);

const titleContaunerClassName = mergeStyles([{
    marginBottom: 10,
    paddingTop: 20,
    paddingLeft: 20,
    width: '98%'
}]);

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};


class ModalProps {
    public showDialog: boolean = false;
    public title: string = "";
    public operationType: OperationType = OperationType.View;
}

export const CustomerUserList : React.FC<ICustomerUserListProps> = (props) => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [users, setUsers] = useState([] as SystemUser[]);
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(new SystemUser());
    const [userModalProps, setUserCardProps] = useState(new ModalProps());
    const [licenseModalProps, setLicenseCardProps] = useState(new ModalProps());
    const [loaderMessage, setLoaderMessage] = useState("Caricamento dati utenti in corso ...");
    const [resetPasswordModalProps, setResetPasswordCardProps] = useState(new ModalProps());
    const [filteredItems, setFilteredItems] = useState<SystemUser[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const navigate = useNavigate();

    const listColumns: IColumn[] = [{
        key: "firstName",
        fieldName: "firstName",
        name: t('customerCenter:CustomerUserSetupList:CustomerUserList:DetailList:columns:firstName'),
        minWidth: 100,
        maxWidth: 200
    },
    {
        key: "lastName",
        fieldName: "lastName",
        name: t('customerCenter:CustomerUserSetupList:CustomerUserList:DetailList:columns:lastName'),
        minWidth: 100,
        maxWidth: 200
    },
    {
        key: "displayName",
        fieldName: "displayName",
        name: t('customerCenter:CustomerUserSetupList:CustomerUserList:DetailList:columns:displayName'),
        minWidth: 100,
        maxWidth: 200
    },
    {
        key: "userPrincipleName",
        fieldName: "userPrincipleName",
        name: t('customerCenter:CustomerUserSetupList:CustomerUserList:DetailList:columns:userPrincipleName'),
        minWidth: 150,
        maxWidth: 250
    },
    {
        key: "mainUser",
        fieldName: "mainUser",
        name: t('customerCenter:CustomerUserSetupList:CustomerUserList:DetailList:columns:mainUser'),
        minWidth: 150,
        maxWidth: 250
    }];

    const selection = new Selection({
        onSelectionChanged: () => {
            if (selection.getSelectedCount() > 0)
                setSelectedUser(selection.getSelection()[0] as SystemUser);
            else
                setSelectedUser(new SystemUser());
        }
    })

    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();

    const buildActions = () : ICommandBarItemProps[] => {
        return [{
            key: "New",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:new:text')!,
            iconProps: {
                iconName: "Add"
            },
            disabled: (isRecordSelected()),
            onClick: () => {
                setSelectedUser(new SystemUser());
                setUserCardProps({
                    operationType: OperationType.Insert,
                    showDialog: true,
                    title: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:new:cardTitle')!
                });
            }
        },
        {
            key: "Edit",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:edit:text')!,
            iconProps: {
                iconName: "Edit"
            },
            disabled: (! isRecordSelected()),
            onClick: () => {
                setUserCardProps({
                    operationType: OperationType.Update,
                    showDialog: true,
                    title: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:edit:cardTitle',{name: selectedUser.displayName})!
                });
            }
        },
        {
            key: "Delete",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:text')!,
            iconProps: {
                iconName: "Delete"
            },
            disabled: (! isRecordSelected()),
            onClick: () => {
                setShowDeleteConfirmDialog(true);
            }
        },
        {
            key: "Update",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:update:text')!,
            iconProps:{
                iconName: "Refresh"
            },
            disabled: false,
            onClick: () => {
                setDataStatus(SystemDataLoadingStatus.ToLoad);
            }
        },
        {
            key: "UsersSubscriptions",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:UsersSubscriptions:text')!,
            iconProps: {
                iconName: "ProfileSearch"
            },
            disabled: false,
            onClick: () => {
                navigate('/app/customer/users/subscriptions');
            }
        },
        {
            key: "LicenseAssign",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:LicenseAssign:text')!,
            iconProps: {
                iconName: "ContactCardSettings"
            },
            disabled: (! isRecordSelected()),
            onClick: () => {
                customerService.syncUsersCspSubscriptions(customerInfoContext.setupConfig.customerId)
                .then(() => {
                    setLicenseCardProps({
                        operationType: OperationType.Insert,
                        title: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:LicenseAssign:cardTitle',{name: selectedUser.displayName})!,
                        showDialog: true
                    });
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                });
            }
        },
        {
            key: "PasswordReset",
            text: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:PasswordReset:text')!,
            iconProps: {
                iconName: "Lock"
            },
            disabled: (! isRecordSelected()),
            onClick: () => {
                setResetPasswordCardProps({
                    operationType: OperationType.Insert,
                    title: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:PasswordReset:cardTitle',{name: selectedUser.displayName})!,
                    showDialog: true
                });
            }
        }];
    }
    
    const filterListItems = (users: SystemUser[],newValue: string) => {
        let filteredItems: SystemUser[] = users;

        if (newValue !== undefined && newValue !== "")
            filteredItems = users.filter((e) => {
                let values = Object.values(e);
                for (let i = 0; i < values.length; i++)
                {
                    let value: string = String(values[i]);
                    if (value != undefined)
                    {
                        if (value.toLocaleLowerCase().indexOf(newValue.toLocaleLowerCase()) >= 0)
                            return true;
                    }
                }

                return false;
            });
        
        setFilteredItems(filteredItems);
    }

    const isRecordSelected = () => {
        return (selectedUser.id != 0);
    }

    const handleSaveUserCard = (user: SystemUser) => {
        setSavingInProgress(true);

        if (userModalProps.operationType === OperationType.Update)
        {
            customerService.loadCustomerUserAvailableLicenses(customerInfoContext.setupConfig.customerId, user.id)
                .then((resp) => {
                    let userInfoSetup: UserInformationSetup = new UserInformationSetup();
                    userInfoSetup.userInformation = user;
                    userInfoSetup.licences = resp as UserLicenseEntry[];

                    customerService.updateUserInformations(customerInfoContext.setupConfig.customerId, userInfoSetup)
                        .then((resp) => {
                            hideUserCard();
                            setLoaderMessage("Caricamento dati utenti in corso ...");
                            setDataStatus(SystemDataLoadingStatus.ToLoad);
                            ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerUserSetupList:CustomerUserList:Text00001lbl')!);
                        })
                        .catch((err) => {
                            setSavingInProgress(false);
                            ToastService.showMessage(MessageType.Error, err);
                        });
                })
                .catch((err) => {
                    setSavingInProgress(false);
                    ToastService.showMessage(MessageType.Error, err);
                })
        }
        else
        {
            let backupUser = user;
            let backupUserPrincipleName = user.userPrincipleName;
            let userInfoSetup: UserInformationSetup = new UserInformationSetup();
            userInfoSetup.userInformation = user;
            userInfoSetup.userInformation.userPrincipleName += "@" + customerInfoContext.defaultDomain.tenantDomain; 
            userInfoSetup.userInformation.customerId = customerInfoContext.setupConfig.customerId;
            userInfoSetup.licences = [] as UserLicenseEntry[];
            customerService.updateUserInformations(customerInfoContext.setupConfig.customerId, userInfoSetup)
                .then((resp) => {
                    hideUserCard();
                    setLoaderMessage("Caricamento dati utenti in corso ...");
                    setDataStatus(SystemDataLoadingStatus.ToLoad);
                    ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerUserSetupList:CustomerUserList:Text00001lbl')!);
                })
                .catch((err) => {
                    setSavingInProgress(false);
                    backupUser.userPrincipleName = backupUserPrincipleName;
                    setSelectedUser(backupUser);
                    ToastService.showMessage(MessageType.Error, err);
                });
        }
    };

    const handleLicensesSave = (licenses: UserLicenseEntry[]) => {
        let userInfoSetup: UserInformationSetup = new UserInformationSetup();
        userInfoSetup.userInformation = selectedUser;
        userInfoSetup.licences = licenses;
        customerService.updateUserInformations(customerInfoContext.setupConfig.customerId, userInfoSetup)
            .then((resp) => {
                hideLicensesCard();
                ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerUserSetupList:CustomerUserList:Text00001lbl')!);
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err);
            });
    };

    const handleResetPasswordConfirmation = (user: SystemUser, destinationEmail: String) => {
        setLoaderMessage(t('customerCenter:CustomerUserSetupList:CustomerUserList:LoaderMessage:resettingPassword')!);
        setDataStatus(SystemDataLoadingStatus.Loading);
        customerService.resetUserPassword(user, destinationEmail)
            .then((resp) => {
                hideResetPasswordCard();
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerUserSetupList:CustomerUserList:LoaderMessage:resettedPassword')!);
            })
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error, err.message);
            });
    };

    const hideUserCard = () => {
        setSavingInProgress(false);
        setUserCardProps({
            showDialog: false,
            title: userModalProps.title,
            operationType: userModalProps.operationType
        });
    }

    const hideLicensesCard = () => {
        setLicenseCardProps({
            operationType: licenseModalProps.operationType,
            showDialog: false,
            title: licenseModalProps.title
        });
    }

    const hideResetPasswordCard = () => {
        setResetPasswordCardProps({
            operationType: resetPasswordModalProps.operationType,
            showDialog: false,
            title: resetPasswordModalProps.title
        });
    }

    const handleOnDeleteCancel = () => {
        setShowDeleteConfirmDialog(false);
    }

    const handleOnDeleteConfirm = () => {
        setLoaderMessage(t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:LoaderMessage:messageContent1')!);
        setDataStatus(SystemDataLoadingStatus.Loading);
        if (selectedUser !== undefined) {
            customerService.deleteUser(selectedUser)
            .then((resp) => {
                setShowDeleteConfirmDialog(false);
                setLoaderMessage(t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:LoaderMessage:messageContent2')!);
                ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:LoaderMessage:Text00001Lbl')!);
                setDataStatus(SystemDataLoadingStatus.ToLoad);
            })
            .catch((err) => {
                setShowDeleteConfirmDialog(false);
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:LoaderMessage:Err00001Text')!);
            });
            
        }
    }

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            setLoaderMessage(t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:LoaderMessage:messageContent1')!);
            customerService.syncUsers(customerInfoContext.setupConfig.customerId)
                .then(() => {
                    customerService.getCustomerUsers(customerInfoContext.setupConfig.customerId)
                    .then((resp) => {
                        setUsers(resp);

                        if(searchText !== undefined && searchText !== "")
                            filterListItems(resp,searchText);
                        else
                            setFilteredItems(resp);

                        setDataStatus(SystemDataLoadingStatus.Loaded);            
                        setLoaderMessage("");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                })
                .catch((err) => {
                    customerService.getCustomerUsers(customerInfoContext.setupConfig.customerId)
                        .then((resp) => {
                            setUsers(resp);

                            if(searchText !== undefined && searchText !== "")
                                filterListItems(resp,searchText);
                            else
                                setFilteredItems(resp);

                            setDataStatus(SystemDataLoadingStatus.Loaded);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        }
    }, [dataStatus]);

    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        if (! props.standalone)
        {
            return (
                <>
                    <CustomerUserListActions items={buildActions()} />
                    <Stack className={titleContaunerClassName}>
                        <Text className={titleClassName}>{t('customerCenter:CustomerUserSetupList:CustomerUserList:listTitle')!}</Text>
                        <Separator styles={separatorStyles} />
                        <Stack.Item>
                            <SearchBox onChange={(e, newValue) => {
                                setSearchText(newValue!);
                                filterListItems(users,newValue!);
                            }} placeholder={t('common:PageContainer:SearchBox:placeholder')!} iconProps={{iconName: "Filter"}} styles={{root: {
                                width: '100%'            
                            }}} 
                            value={searchText} />
                        </Stack.Item>
                    </Stack>
                    <MarqueeSelection selection={selection}>
                        <ShimmeredDetailsList 
                            columns={listColumns}
                            items={filteredItems}
                            selection={selection}
                            enableShimmer={! SystemCore.isFinishedDataStatus(dataStatus)}
                            selectionMode={SelectionMode.single}
                            key={"id"}
                        >
                        </ShimmeredDetailsList>
                    </MarqueeSelection>
                    <CustomerUserCard showDialog={userModalProps.showDialog} 
                                    user={selectedUser}
                                    savingInProgress={savingInProgress}
                                    title={userModalProps.title}
                                    operationType={userModalProps.operationType}
                                    onSuccessModal={handleSaveUserCard}
                                    onCancelModal={hideUserCard}
                    />
                    <CustomerLicenseCard showDialog={licenseModalProps.showDialog}
                                        title={licenseModalProps.title}
                                        user={selectedUser}
                                        onCancelModal={hideLicensesCard}
                                        onSuccessModal={handleLicensesSave}
                    />
                </>
            );
        }
        else
        {
            return (
                <Stack className={pageContainerClassName}>
                    <Stack.Item className={mainContainerClassName}>
                        <CustomerUserListActions items={buildActions()} />
                        <Stack className={titleContaunerClassName}>
                            <Text className={titleClassName}>{t('customerCenter:CustomerUserSetupList:CustomerUserList:listTitle')!}</Text>
                            <Separator styles={separatorStyles} />
                            <Stack.Item>
                                <SearchBox onChange={(e, newValue) => {
                                    setSearchText(newValue!);
                                    filterListItems(users,newValue!);
                                }} placeholder={t('common:PageContainer:SearchBox:placeholder')!} iconProps={{iconName: "Filter"}} styles={{root: {
                                    width: '100%'            
                                }}} 
                                value={searchText} />
                            </Stack.Item>
                        </Stack>
                        <MarqueeSelection selection={selection}>
                            <ShimmeredDetailsList 
                                columns={listColumns}
                                items={filteredItems}
                                selection={selection}
                                enableShimmer={! SystemCore.isFinishedDataStatus(dataStatus)}
                                selectionMode={SelectionMode.single}
                                key={"id"}
                            >
                            </ShimmeredDetailsList>
                        </MarqueeSelection>
                        <CustomerUserCard showDialog={userModalProps.showDialog} 
                                        user={selectedUser}
                                        savingInProgress={savingInProgress}
                                        title={userModalProps.title}
                                        operationType={userModalProps.operationType}
                                        onSuccessModal={handleSaveUserCard}
                                        onCancelModal={hideUserCard}
                        />
                        <CustomerLicenseCard showDialog={licenseModalProps.showDialog}
                                            title={licenseModalProps.title}
                                            user={selectedUser}
                                            onCancelModal={hideLicensesCard}
                                            onSuccessModal={handleLicensesSave}
                        />
                        <ConfirmDialog title={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:title')!}
                            subText={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:subText', {name:selectedUser.displayName})!}
                            showDialog={showDeleteConfirmDialog}
                            cancelButtonText={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:cancelButtonText')!}
                            confirmButtonText={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:delete:ConfirmDialog:confirmButtonText')!}
                            onCancel={handleOnDeleteCancel}
                            onConfirm={handleOnDeleteConfirm}
                        />
                        <CustomerResetPasswordCard showDialog={resetPasswordModalProps.showDialog}
                            title={resetPasswordModalProps.title}
                            user={selectedUser}
                            onCancelModal={hideResetPasswordCard}
                            onSuccessModal={handleResetPasswordConfirmation}
                        />
                    </Stack.Item>
                </Stack>
            );
        }
}