import { t } from "i18next";
import { useContext } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { SystemPageType } from "../../../Model/SystemModels";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";

export const CustomerUserSubscriptionList : React.FC = () => {
    const customerInfoContext = useContext(CustomerInformationContext);

    const buildEndpoint = () : string => {        
        const customerId: number = customerInfoContext.setupConfig.customerId;
        const endpoint: string = "/api/csp/customer/"+customerId+"/users/subscriptions";                
        return endpoint;
    }

    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserListActions:UsersSubscriptions:cardTitle')!} backAllowed shareAllowed={false}> 
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="userDisplayName" name={t('customerCenter:CustomerUserSetupList:CustomerUserSubscriptionList:fields:userDisplayName')!} minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="userEmail" name={t('customerCenter:CustomerUserSetupList:CustomerUserSubscriptionList:fields:userEmail')!} minWidth={600} maxWidth={700} />
                    <ListHeaderEntry fieldName="productSkuName" name={t('customerCenter:CustomerUserSetupList:CustomerUserSubscriptionList:fields:productSkuName')!} minWidth={600} maxWidth={700} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}