import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSharepointDirectoryCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda partner business contract"} endpoint="odata/sharepoint/directory" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField label="Abilitata" name="enabled" type={SystemFieldType.Boolean} />
                <CardField label="Tipo entità" name="entityType" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "Partner"},
                    {key: 1, text: "Cliente"}
                ]} readonly />
                <CardField label="Id sito" name="siteId" type={SystemFieldType.String} readonly />
                <CardField label="Sito" name="siteDisplayName" type={SystemFieldType.String} readonly />
                <CardField label="Id lista" name="listId" type={SystemFieldType.String} readonly />
                <CardField label="Lista" name="listName" type={SystemFieldType.String} readonly />
                <CardField label="Id directory" name="directoryId" type={SystemFieldType.String} readonly />
                <CardField label="Nome" name="name" type={SystemFieldType.String} readonly />
                <CardField label="URL" name="folderUrl" type={SystemFieldType.String} readonly />
                <CardField label="Id referenza directory" name="directoryRefId" type={SystemFieldType.String} readonly />
            </CardGroup>
        </PageContainer>
    );
});
