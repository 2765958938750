export class AdminDashboardCue {
    public newTrialCustomer: number = 0;
    public trialProvisioning: number = 0;
    public trialOnboarding: number = 0;
    public trialSwitch: number = 0;
    public trialLost: number = 0;
    public newDsCustomer: number = 0;
    public customerProvisioning: number = 0;
    public customerOnboarding: number = 0;
    public customerLive: number = 0;
    public customerLost: number = 0;
    public customerRisk:number = 0;
    public customerActive:number = 0;
    public customer: number = 0;
    public newCustomer: number = 0;
    public newTrial: number = 0;
    public user: number = 0;
    public partnerId: number = 0;
    public revenue: number = 0;
    public newRevenue: number = 0;
    public signedContract: number = 0;
    public customerNamirialFEContract: number = 0;
    public customerUnsignedFEContract: number = 0;
    public customerUnsignedBaseContract: number = 0;
    public customerChecklistInProgress: number = 0;
    public subscriptionCount: number = 0;
    public subscriptionSum: number = 0;
    public newSubscriptionSum: number = 0;
    public invoice: number = 0;
    public trialCustomer: number = 0;
    public groupCodeFilter: string = "";

    public awareness: number = 0;
    public subscribed: number = 0;
    public interest: number = 0;
    public marketingQualified: number = 0;
    public firstLogin: number = 0;
    public selfServiceQualified: number = 0;
    public demoWaiting: number = 0;
    public demoCompleted: number = 0;
    public trial: number = 0;
    public buy: number = 0;
    
    public leadLost:number = 0;
    public leadWin:number = 0;
    public leadActive:number = 0;
    public leadUnqualified:number = 0;
    public parkedLead:number = 0;    
    public eosAppLeads: number = 0;
}

export interface ILocation {
    state: { back: string };
}

export class AdminHelpdeskDashboardCue {
    public trialNew: number = 0;
    public trialProvisioning: number = 0;
    public trialOnboarding: number = 0;
    public trialSwitch: number = 0;
    public customerNew: number = 0;
    public customerProvisioning: number = 0;
    public customerOnboarding: number = 0;
    public customerLive: number = 0;
    public customerRisk: number = 0;
    public userIncidentInProgress: number = 0;
    public userIncidentLate: number = 0;
    public teamIncidentOpen: number = 0;
    public teamIncidentOpenLate: number = 0;
    public teamIncidentInProgress: number = 0;
    public teamIncidentInProgressLate: number = 0;
    public teamIncidentResolved: number = 0;
    public teamIncidentNotAssocied: number = 0;
    public teamIncidentNotMapped: number = 0;
    public teamIncidentNotMappedLastMonth: number = 0;
    public teamIncidentFAQMissing: number = 0;
    public teamIncidentFAQMissingLastMonth: number = 0;
    public newRevenue: number = 0;
    public subscriptionSum: number = 0;
    public newSubscriptionSum: number = 0;
    public eosAppUserIncidentInProgress: number = 0;
    public eosAppUserIncidentLate: number = 0;
    public eosAppTeamIncidentOpen: number = 0;
    public eosAppTeamIncidentOpenLate: number = 0;
    public eosAppTeamIncidentInProgress: number = 0;
    public eosAppTeamIncidentInProgressLate: number = 0;
    public eosAppTeamIncidentResolved: number = 0;
    public eosAppTeamIncidentNotAssocied: number = 0;
    public eosAppTeamIncidentNotMapped: number = 0;
    public eosAppTeamIncidentNotMappedLastMonth: number = 0;
    public eosAppTeamIncidentFAQMissing: number = 0;
    public eosAppTeamIncidentFAQMissingLastMonth: number = 0;
}

export class BCNProcessTest {
    public processModule: string = "";
    public processCode: string = "";
    public lineNo: number = 0;
}

export class AssignedChatbotBusinessLine {
    public enabled: boolean = false;
    public profileCode: string = "";
    public businessLineCode: string = "";
    public description: string = "";
    public systemId: string = "";
}

export class BusinessCentralCompany {
    public id: string = "";
    public displayName: string = "";
    public businessCentralCompanyId: string = "";
}

export enum BusinessCentralDefaultAppType {
    PerTenant,
    ISV
}

export class StripeCouponRequest {
    public name: string = "";
    public amountOff: number | undefined = undefined;
    public percentOff: number | undefined = undefined;
    public duration: number = 0;
    public durationInMonths: number | undefined = undefined;
    public appliesTo: string[] = [];
    public redeemBy: Date | undefined = undefined;
    public maxRedemptions: number | undefined = undefined;
}

export class StripeProduct {
    public id: string = "";
    public name: string = "";
    public description: string = "";
}

export class DataSecurityStatus {
    public entityName: string = "";
    public code: string = "";
    public description: string = "";
}